import InfoIcon from "@mui/icons-material/Info";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { getSettings, postSettings } from "../../services/settings-service";
const VehicleSelectLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const Settings = () => {
  const [settings, setSettings] = useState<{ [key: string]: any }>({});
  const loadSettings = async () => {
    const { data: result } = await getSettings();
    setSettings(result.data);
  };
  useEffect(() => {
    loadSettings();
  }, []);
  console.log(settings, "SETTINGS");
  const handleSubmit = async (values: any) => {
    toast.promise(postSettings(values), {
      loading: "Loading...",
      success: (res) => {
        return `Settings saved!`;
      },
      error: ({ response }) => {
        return "Error when saving settings";
      },
    });
    await postSettings(values);
  };
  const initialValues = {
    signalPhoneNumber: "",
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography sx={{ paddingBottom: 2 }} variant="h4">
        Settings
      </Typography>
      <Formik
        enableReinitialize
        initialValues={settings || {}}
        onSubmit={async (values: any) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="signalPhoneNumber"
                  name="signalPhoneNumber"
                  label={
                    <VehicleSelectLabel>
                      <span>Signal notifications phone number</span>{" "}
                      <Tooltip
                        title={`Used to send signal messages to clients. Must include country code!`}
                      >
                        <InfoIcon></InfoIcon>
                      </Tooltip>
                    </VehicleSelectLabel>
                  }
                  value={values.signalPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Button
              sx={{ marginTop: 5 }}
              variant="contained"
              type="submit"
              autoFocus
              onClick={() => {}}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
