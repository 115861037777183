import axiosIntance from "../axiosInstance";
import axiosInstanceFiles from "../axiosInstanceFiles";
import { UserRoles } from "../utils";
export type UserRolesType = "ADMIN" | "USER";
export interface IUser {
    id: string,
    _id?: string,
    nickname?: string,
    mainColor?: string,
    secondaryColor?: string,
    econtOffice?: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    balanceSheetUrl?: string,
    role: UserRoles,
    isAdmin?: boolean,
    allowBranding?: boolean,
    password?: string,
    logo?: string,
    logoImage?: any
}
interface IUsersResponse {
    count: number,
    data: IUser[]
}
export const getUsers = (roles?: UserRoles[]) => {
    return axiosIntance.get<IUsersResponse>(`/users`, {
    params: {
        role: roles?.join(",")
    }});
};
export const getUser = (userId: string) => {
    return axiosIntance.get<IUser>(`/users/${userId}`);
};
export const createUser = (values: IUser) => {
    return axiosIntance.post<IUser>(`/auth/signup`, values);
};
export const updateUser = (userId: string, values: IUser) => {
    console.log(values, "VALES");
    return axiosIntance.put<IUser>(`/users/${userId}`, values);
};
export const deleteUser = (userId: string) => {
    return axiosIntance.delete<IUser>(`/users/${userId}`);
};
export const updatePassword = (email: string, password: string) => {
    return axiosIntance.put<IUser>(`/auth/password/update`, {email, password});
};

export const uploadLogo = (userId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/logo/user/${userId}`, formData);
};

export const updateUserMethod = async (userId: string, values: IUser) => {
    if (values.logoImage) {
        const formData = new FormData();
        formData.append("logo", values.logoImage);
        await uploadLogo(userId, formData);
    }
    await updateUser(userId, values);
};