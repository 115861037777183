import { useEffect, useState } from "react";
import { getCurrentUser } from "../../../services/auth-service";
import { getVehicle, IVehicle, searchVehicle } from "../../../services/vehicle-service";

export const useVehicle = (vin: string | undefined, share?:boolean) => {
  const user = getCurrentUser();
  // state to keep track of loading
  const [loadingData, setLoadingData] = useState(false);

  // state for data itself
  const [vehicleData, setVehicleData] = useState<IVehicle | undefined>({} as IVehicle);

  const fetchData = async (searchString: string) => { // it could be vin or vehicleId
    try {
      setLoadingData(true)
      const vehicleData = (user?.id || share) ? await getVehicle(searchString) : await searchVehicle(searchString);
      if(vehicleData)
      setVehicleData(vehicleData.data);
      setLoadingData(false)
    } catch (error) {
      console.log("error", error);
    }
  };

  // effect to fetch data
  useEffect(() => {
    if(!vin) {
        return;
    }
    fetchData(vin);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin]);

  // return the data and loading state from this hook
  return [loadingData, vehicleData] as const;
};