import { SvgIconComponent } from '@mui/icons-material';
import React from 'react';
const MUIcon: { [key: string]: SvgIconComponent } = require('@mui/icons-material');

type StatusIconProps = {
    iconName: keyof typeof MUIcon;
}

export const StatusIcons : { [key: string]: any } = {
    TRANSIT: "LocationOn",
    LOADED: "DirectionsBoat",
    UNLOADED: "Warehouse",
    DELIVERED: "SportsScore",
    LOADED_ON_THE_TRUCK: "LocalShipping",
    AT_TERMINAL: "LocalShipping",
    TRANSHIPMENT: "DirectionsBoat",
  };
export const StatusIcon: React.FC<StatusIconProps>  = ({iconName}) => {
    console.log(iconName, "ICON NAME")
    const Icon = MUIcon[iconName];

    return (
       Icon && <Icon color='primary' />
    )
  }