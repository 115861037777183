import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { MuiColorInput, MuiColorInputFormat } from "mui-color-input";
import React from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { getCurrentUser } from "../../services/auth-service";
import { IUser, getUser, updateUserMethod } from "../../services/user-service";

const ProfileComponent = styled.div``;
const { useCallback, useEffect, useState, useRef } = React;
export const Profile: React.FC = () => {
  const logoFileRef = useRef<HTMLInputElement>(null);

  const format: MuiColorInputFormat = "hex";
  const user = getCurrentUser();
  const [logoPreview, setLogoPreview] = useState<any>(null);
  const [userData, setUserData] = useState<IUser>();
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    logoFileRef?.current?.click();
  };

  const loadUserData = useCallback(async () => {
    if (user?.id) {
      const { data } = await getUser(user.id);
      setUserData(data);
    }
  }, [user?.id]);
  const handleSubmit = async (values: any) => {
    if (user) {
      toast.promise(updateUserMethod(user?.id, values), {
        loading: "Loading...",
        success: (res) => {
          return `Branding saved successfully`;
        },
        error: ({ response }) => {
          return "Something went wrong";
        },
      });
    }
  };
  useEffect(() => {
    if (user?.id) {
      loadUserData();
    }
  }, [user?.id, loadUserData]);
  return (
    <ProfileComponent>
      <Typography sx={{ marginBottom: 5 }} variant="h4">
        Branding
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          secondaryColor: userData?.secondaryColor || "#fff",
          mainColor: userData?.mainColor || "#fff",
        }}
        onSubmit={async (values: any) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MuiColorInput
                  label="Main Color"
                  value={values.mainColor}
                  onChange={(value: string) => {
                    setFieldValue("mainColor", value);
                  }}
                  format={format}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Secondary Color</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <MuiColorInput
                  value={values.secondaryColor}
                  onChange={(value: string) => {
                    setFieldValue("secondaryColor", value);
                  }}
                  format={format}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  onChange={(e: any) => {
                    setLogoPreview(URL.createObjectURL(e.target.files[0]));
                    setFieldValue("logoImage", e.target.files[0]);
                  }}
                  ref={logoFileRef}
                  hidden
                  type="file"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onBtnClick}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload logo
                </Button>
              </Grid>

              <Grid item xs={12}>
                {logoPreview && <img width={80} alt="logo" src={logoPreview} />}
              </Grid>
            </Grid>
            <Button sx={{ marginTop: 15 }} variant="contained" type="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </ProfileComponent>
  );
};
