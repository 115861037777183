import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { getCurrentUser } from "../services/auth-service";


const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({theme}) => ({
  marginLeft: "auto !important",
  width: isMobile ? "100% !important" : "auto",
  padding: "6px !important",
  border: `2px solid ${theme.blueBackground} !important`
}));
export const CustomToolbar = () => {
  const user = getCurrentUser();
  return (
    <GridToolbarContainer style={{justifyContent: "space-between"}}>
      {user?.isAdmin && !isMobile && (
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          
        </div>
      )}
      <StyledGridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
