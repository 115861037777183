import axios from 'axios';
const baseURL = 'https://api.autosrealm.com/';
const accessToken = localStorage.getItem("accessToken");
const axiosIntanceFiles = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + accessToken
    },
    transformResponse: [
        (data) => {
            if(data)
            return JSON.parse(data || '');
        },
    ],
});
export default axiosIntanceFiles;