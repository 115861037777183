import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styled from "styled-components";
const DialogContainer = styled.div.attrs(
  (props: { iconColor: string; backgroundColor: string }) => props
)`
  padding: 20px;
  .MuiListItemIcon-root svg {
    color: ${(props) => props.iconColor} !important;
  }
  .MuiStepLabel-iconContainer > div {
    background: ${(props) => props.backgroundColor} !important;
  }
`;
interface CommonDialogProps {
  disabled?: boolean;
  headerBackground?: string;
  backgroundColor?: string;
  handleToggleOpen: () => void;
  handleSubmit?: () => void;
  children?: React.ReactNode;
  open: boolean;
  title?: string;
}
export const CommonDialog: React.FC<CommonDialogProps & DialogProps> = ({
  open,
  title,
  disabled,
  handleToggleOpen,
  children,
  handleSubmit,
  fullScreen,
  headerBackground,
  backgroundColor,
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleToggleOpen}
    >
      <AppBar sx={{ background: headerBackground || "", position: "relative" }}>
        <Toolbar>
          <IconButton
            sx={{ boxShadow: 5 }}
            edge="start"
            onClick={handleToggleOpen}
            color="inherit"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {handleSubmit && (
            <Button
              disabled={disabled}
              variant="contained"
              sx={{ boxShadow: 5 }}
              autoFocus
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContainer
        iconColor={headerBackground}
        backgroundColor={backgroundColor}
      >
        {children}
      </DialogContainer>
    </Dialog>
  );
};
