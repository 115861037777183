import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { IShipper } from "../../services/shipper-service";

interface ContainerFormProps {
  containerData?: IShipper;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const ShipperForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  let validationSchema = object({
    name: string().required("Auction name is required"),
  });
  const userValues = {
    name: "",
  } as IShipper;
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={containerData || userValues}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({ values, handleChange, handleBlur, touched, errors }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
