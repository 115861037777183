import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from "react-image-gallery";
import styled from "styled-components";

interface IGalleryProps {
  removeImage?: (item: ReactImageGalleryItem) => void;
}
const SingleImage = styled.span`
  display: block;
  position: relative;
  img {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }
  > div {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`;
const GalleryThumbnail = styled.span`
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 500px;
    width: auto;
    height: auto;
  }
`;
export const ImageGalleryComponent = React.memo(function Images(
  props: IGalleryProps & ReactImageGalleryProps
) {
  return <ImageGalleryRenderer removeImage={props.removeImage} {...props} />;
});
const ImageGalleryRenderer: React.FC<
  IGalleryProps & ReactImageGalleryProps
> = ({ items, showThumbnails = true, removeImage }) => {
  const renderGalleryThumbnail = React.useCallback(
    (item: ReactImageGalleryItem) => {
      const handleRemoveImage = (item: ReactImageGalleryItem) => {
        if (removeImage) removeImage(item);
      };
      return (
        <SingleImage>
          {removeImage && (
            <div onClick={() => handleRemoveImage(item)}>
              <DeleteIcon color="error" fontSize="small" />
            </div>
          )}
          <img
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
          />
        </SingleImage>
      );
    },
    [removeImage]
  );
  const renderGalleryItem = React.useCallback(
    (item: ReactImageGalleryItem | any) => {
      return (
        <GalleryThumbnail>
          <img
            loading="lazy"
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
          />
        </GalleryThumbnail>
      );
    },
    []
  );
  const filteredItems = items.filter((item:any) => {
    return typeof item.original === "string";
  })
  return (
    <ImageGallery
      showPlayButton={false}
      showThumbnails={false}
      // renderItem={renderGalleryItem}
      // renderThumbInner={renderGalleryThumbnail}
      items={filteredItems}
    />
  );
};
