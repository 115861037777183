import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { WindowContext } from "../../context/WindowContextProvider";
import { deleteShippingLine, getShippingLines } from "../../services/shipping-line-service";
import { GridActions } from "../../utils";
import { ShippingLineDialog } from "./ShippingLineDialog";
const ShippingLinesActions = {
  ADD: "add-line",
};
export const ShippingLines = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [containers, setContainers] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [containerToDelete, setContainerToDelete] = useState<
    any | undefined
  >(undefined);
  const { clientWidth } = useContext(WindowContext);
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "createdAt",
      headerName: "Creation date",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return dayjs(params.value).format("YYYY-MM-DD")
      }
    },
    {
      field: "trackUrl",
      headerName: "Tracking url",
      flex: 1,
    },
    {
      field: "date",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <ActionMenu>
          <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
          <IconButton onClick={() => handleDeleteContainer(params.row)}>
            <DeleteForeverIcon color="error" fontSize="medium" />
          </IconButton>
        </ActionMenu>
      ),
    },
  ];
  const handleDeleteContainer = (container: any) => {
    setContainerToDelete(container);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (containerToDelete) await deleteShippingLine(containerToDelete.id);
      toast.success("Shipping line deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
    loadShippingLines();
    setContainerToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenDetails = (shippingLine?: any) => {
    navigate(
      !params.auctionId
        ? `/shipping-lines/edit-line/${shippingLine?.id}`
        : "/shipping-lines"
    );
  };
  const handleToggleOpen = () => {
    navigate(!params.action ? "/shipping-lines/add-line" : "/shipping-lines");
  };
  const loadShippingLines = async () => {
    const { data: shippingLines } = await getShippingLines();
    setContainers(shippingLines.data);
  };
  useEffect(() => {
    loadShippingLines();
  }, []);
  useEffect(() => {
    setOpen(params.action === ShippingLinesActions.ADD || !!params.shippingLineId);
  }, [params.action, params.shippingLineId]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Shipping lines</Typography>
        <Button variant="contained" onClick={handleToggleOpen}>
          Add shipping line
        </Button>
        {open && <ShippingLineDialog submitCallback={loadShippingLines} handleToggleOpen={handleToggleOpen} />}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="Are you sure to delete this line?"
      >
        Deleting shipping line <strong>{containerToDelete?.name}</strong>
      </ConfirmDialog>
      <div style={{ height: "calc(100vh - 150px)", width: "100%" }}>
        <DataGrid
          onSelectionModelChange={(itm) => console.log(itm)}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={containers}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
          //checkboxSelection
        />
      </div>
    </Box>
  );
};
