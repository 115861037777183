import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import dayjs from "dayjs";
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ContainerDetails } from "../../pages/Containers/ContainerDetails";
import { ContainerDialog } from "../../pages/Containers/ContainerDialog";
import { ITruck } from "../../pages/Trucks";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import {
  IDateRange,
  IVehicle,
  searchEvents,
} from "../../services/vehicle-service";
import { UserRoles } from "../../utils";
const EventRender = styled.div<{eventStatus: string}>`
  display: flex;
  alignItems: center;
  background: ${({eventStatus, theme}) => eventStatus === "DELIVERED" ? 'green' : theme.blueBackground};
`;
const CalendarContainer = styled.div`
  .rbc-today {
    border: ${({ theme }) => `2px solid ${theme.blueBackground}`};
    background: transparent;
  }
  .rbc-off-range {
    color: transparent;
    pointer-events: none;
  }
  .rbc-off-range-bg {
    background: ${({ theme }) => theme.background};
  }
  .rbc-show-more {
    color: ${({ theme }) => theme.textColor};
  }
  .rbc-btn-group {
    button {
      color: ${({ theme }) => theme.textColor};
    }
  }
`;
export const EVENT_TYPES = {
  TRUCK: "truck",
  VEHICLE: "vehicle",
  CONTAINER: "container",
} as const;
export const EventCalendar = () => {
  const user = getCurrentUser();
  const [containerToEdit, setContainerToEdit] = useState<IContainer>();
  const [openContainerPreviewDialog, setOpenContainerPreviewDialog] = useState<boolean>(false);
  const [openContainerEditDialog, setOpenContainerEditDialog] = useState<boolean>(false);
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [events, setEvents] = useState<any>([]);
  const initialValues = {
    start: dayjs(firstDay).format("YYYY-MM-DD"),
    end: dayjs(lastDay).format("YYYY-MM-DD"),
  };
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<IDateRange>(initialValues);
  const localizer = momentLocalizer(moment);
  const onRangeChange = (range: IDateRange) => {
    setDateRange({
      start: dayjs(range.start).format("YYYY-MM-DD"),
      end: dayjs(range.end).format("YYYY-MM-DD"),
    });
  };
  const eventDates = (date?: string): IDateRange => {
    return {
      start: dayjs(date).format("YYYY-MM-DD"),
      end: dayjs(date).format("YYYY-MM-DD"),
    };
  };
  const trucksData = useMemo(() => {
    return events.trucks?.map((obj: ITruck) => ({
      ...obj,
      title: obj.plateNumber,
      type: EVENT_TYPES.TRUCK,
      ...eventDates(obj.expectedDate),
    }));
  }, [events.trucks]);
  const containersData = useMemo(() => {
    return events.containers?.map((obj: IContainer) => ({
      ...obj,
      title: obj.name,
      type: EVENT_TYPES.CONTAINER,
      ...eventDates(obj.expectedDate),
    }));
  }, [events.containers]);
  const vehiclesData = useMemo(() => {
    return events.vehicles?.map((obj: IVehicle) => ({
      ...obj,
      title: obj.description,
      type: EVENT_TYPES.VEHICLE,
      ...eventDates(obj.expectedDate),
    }));
  }, [events.vehicles]);
  const vehicleEvents = [...(vehiclesData || [])];
  const trucksAndContainersEvents = [
    ...(containersData || []),
    ...(trucksData || []),
  ];
  const loadVehicles = useCallback(async () => {
    const { data: eventsData } = await searchEvents(dateRange);
    setEvents(eventsData.data);
  }, [dateRange]);
  useEffect(() => {
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);
  const onSelectEvent = (event: any) => {
    switch (event.type) {
      case EVENT_TYPES.CONTAINER:
        handleOpenContainerPreview(event)
        return;
      case EVENT_TYPES.TRUCK:
        navigate(`search-truck/${event._id}`);
        return;
      default:
        navigate(`search-vehicle/${event.vin}`);
    }
  };
  const getEventIcon = (event: any) => {
    switch (event.type) {
      case EVENT_TYPES.CONTAINER:
        return <DirectionsBoatIcon fontSize="small" />;
      case EVENT_TYPES.TRUCK:
        return <LocalShippingIcon fontSize="small" />;
      default:
        return <DriveEtaIcon fontSize="small" />;
    }
  };
  const EventComponent = (data: any) => {
    const { event } = data;
    return (
      <EventRender eventStatus={event.status}>
        {getEventIcon(event)}
        {event.title}
      </EventRender>
    );
  };
  moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const handleOpenContainerPreview = (container?: IContainer) => {

  setOpenContainerPreviewDialog(prev => !prev);
  if(container) {
    //@ts-ignore
    setContainerToEdit(container);
  } else {
    setContainerToEdit(undefined)
  }
}
const handleOpenContainerEdit = (container?: IContainer) => {
  setOpenContainerEditDialog(prev => !prev);
}
  return (
    <CalendarContainer>
      <Calendar
        components={{ event: EventComponent }}
        onSelectEvent={(event: any) => onSelectEvent(event)}
        popup
        startAccessor={(event) => {
          return new Date(event.start);
        }}
        endAccessor={(event) => {
          return new Date(event.end);
        }}
        // showAllEvents
        views={{
          month: true,
          week: true,
        }}
        // onDoubleClickEvent={(event) => navigate(`search/${event.vin}`)}
        onRangeChange={(range) => onRangeChange(range as any)}
        localizer={localizer}
        events={
          user?.role === UserRoles.CLIENT
            ? vehicleEvents
            : trucksAndContainersEvents
        }
        style={{ height: "calc(100vh - 150px)" }}
      />
        {openContainerPreviewDialog && (
          <ContainerDetails handleOpenContainerEdit={handleOpenContainerEdit} handleToggleOpen={handleOpenContainerPreview} containerData={containerToEdit} />
        )}
        {openContainerEditDialog && (
          <ContainerDialog redirectBack={false} submitCallback={handleOpenContainerEdit} handleToggleOpen={handleOpenContainerEdit} containerId={containerToEdit?._id} />
        )}
    </CalendarContainer>
  );
};
