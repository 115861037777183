import axiosIntance from "../axiosInstance";
export interface IShipper {
    id: string,
    name: string,
  }
export interface IShipperResponse {
    count: number,
    data: IShipper[]
}
export const getShippers = () => {
    return axiosIntance.get<IShipperResponse>(`/shippers`);
};
export const getShipper = (shipperId: string) => {
    return axiosIntance.get<IShipper>(`/shippers/${shipperId}`);
};
export const createShipper = (values: any) => {
    return axiosIntance.post<IShipper>("/shippers", values);
}
export const updateShipper = (shipperId: string, values: any) => {
    return axiosIntance.put<IShipper>(`/shippers/${shipperId}`, values);
}
export const deleteShipper = (shipperId: string) => {
    return axiosIntance.delete<IShipper>(`/shippers/${shipperId}`);
};