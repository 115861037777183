import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import HomeIcon from "@mui/icons-material/Home";
import LightModeIcon from "@mui/icons-material/LightMode";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Themes } from "../../App";
import { getCurrentUser, logout } from "../../services/auth-service";
import { UserRoles, mobileVersionWidth } from "../../utils";
import { SettingsMenu } from "../SettingsMenu";
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 20px;
  white-space: nowrap;
  @media screen and (max-width: ${mobileVersionWidth}px) {
    flex-direction: row;
    gap: 17px;
  }
`;
const SidebarContainer = styled.section`
  width: 70px;
  height: 95vh;
  background-image: linear-gradient(to top, #1d1e20, #1976d2);
  border-radius: 26px;
  padding: 30px 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: ${mobileVersionWidth}px) {
    height: auto;
    flex-direction: row;
    overflow-x: scroll;
    border-radius: 0;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }
  a {
    color: #fff;
    padding: 8px;
    &.active {
      background: #9dabce;
      border-radius: 6px;
    }
  }
`;
const IconHolder = styled.div`
  display: flex;
  align-items: center;
`;
interface SidebarProps {
  themeToggler: () => void;
  theme: string;
}
export const Sidebar: React.FC<SidebarProps> = ({ themeToggler, theme }) => {
  const user = getCurrentUser();
  const renderTopMenu = () => {
    return (
      <NavMenu>
        <Tooltip title="Home">
          <NavLink to="/">
            <IconHolder>
              <HomeIcon fontSize="medium" />
            </IconHolder>
          </NavLink>
        </Tooltip>
        <Tooltip title="Vehicles">
          <NavLink to="/vehicles">
            <IconHolder>
              <DriveEtaIcon fontSize="medium" />
            </IconHolder>
          </NavLink>
        </Tooltip>
        {/* {user?.role === "ADMIN" && ( */}
        <>
          <Tooltip title="Containers">
            <NavLink to="/containers">
              <IconHolder>
                <DirectionsBoatIcon fontSize="medium" />
              </IconHolder>
            </NavLink>
          </Tooltip>
          <Tooltip title="Trucks">
            <NavLink to="/trucks">
              <IconHolder>
                <LocalShippingIcon fontSize="medium" />
              </IconHolder>
            </NavLink>
          </Tooltip>
          {user?.role === "ADMIN" && (
            <Tooltip title="Titles shipping">
              <NavLink to="/titles">
                <IconHolder>
                  <ListAltIcon fontSize="medium" />
                </IconHolder>
              </NavLink>
            </Tooltip>
          )}

          {user?.role === "ADMIN" && (
            <Tooltip title="Claims">
              <NavLink to="/claims">
                <IconHolder>
                  <GppMaybeIcon fontSize="medium" />
                </IconHolder>
              </NavLink>
            </Tooltip>
          )}
        </>
        {/* {clientWidth < mobileVersionWidth && (
          <NavMenu style={{ cursor: "pointer" }}>
            <Tooltip onClick={themeToggler} title="Toggle dark mode">
              <IconHolder>
                {theme === Themes.LIGHT ? (
                  <DarkModeIcon style={{ color: "#fff" }} fontSize="medium" />
                ) : (
                  <LightModeIcon style={{ color: "#fff" }} fontSize="medium" />
                )}
              </IconHolder>
            </Tooltip>
            <NavLink to="/profile">
              <IconHolder>
                <PersonIcon fontSize="medium" />
              </IconHolder>
            </NavLink>
            <Tooltip
              style={{ cursor: "pointer" }}
              onClick={() => {
                logout();
              }}
              title="Log out"
            >
              <LogoutIcon style={{ color: "#fff" }} fontSize="medium" />
            </Tooltip>
          </NavMenu>
        )} */}
      </NavMenu>
    );
  };

  const renderBottomMenu = () => {
    return (
      <NavMenu style={{ cursor: "pointer" }}>
        {user?.balanceSheetUrl && (
          <Tooltip title="Balance sheet">
            <Link to={user.balanceSheetUrl} target="_blank">
              <AccountBalanceIcon style={{ color: "#fff" }} />
            </Link>
          </Tooltip>
        )}
        {user?.role === UserRoles.ADMIN && (
          <Tooltip title="Settings">
            <SettingsMenu />
          </Tooltip>
        )}
        <Tooltip
          style={{ cursor: "pointer" }}
          onClick={themeToggler}
          title="Toggle dark mode"
        >
          {theme === Themes.LIGHT ? (
            <DarkModeIcon style={{ color: "#fff" }} fontSize="medium" />
          ) : (
            <LightModeIcon style={{ color: "#fff" }} fontSize="medium" />
          )}
        </Tooltip>
        {user?.allowBranding && (
          <Tooltip title="Branding">
            <NavLink to="/branding">
              <ColorLensIcon fontSize="medium" />
            </NavLink>
          </Tooltip>
        )}
        <Tooltip
          style={{ cursor: "pointer" }}
          onClick={() => {
            logout();
          }}
          title="Log out"
        >
          <LogoutIcon style={{ color: "#fff" }} fontSize="medium" />
        </Tooltip>
      </NavMenu>
    );
  };
  return (
    <SidebarContainer>
      {renderTopMenu()}

      {/* Bottom menu */}
      {renderBottomMenu()}
    </SidebarContainer>
  );
};
