import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { QuickStatusFilter } from "../../components/QuickStatusFilter";
import { getCurrentUser } from "../../services/auth-service";
import {
  deleteContainer,
  getContainers,
  IContainer,
} from "../../services/container-service";
import { IVehicle } from "../../services/vehicle-service";
import { ContainerStatuses, GridActions, UserRoles } from "../../utils";
import { ContainerDetails } from "./ContainerDetails";
import { ContainerDialog } from "./ContainerDialog";
import { LoadedVehiclesDialog } from "./LoadedVehiclesDialog";
const ContainersGrid = styled.div`
   {
    height: calc(100vh - 150px);
    width: 100%;
    .loadedVehicles {
      flex-direction: column;
      justify-content: center !important;
      p {
        width: 100%;
      }
    }
  }
`;
export const Containers = () => {
  
  const [containerToEdit, setContainerToEdit] = useState<IContainer>();
  const [openContainerPreviewDialog, setOpenContainerPreviewDialog] = useState<boolean>(false);
  const [openContainerDialog, setOpenContainerDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [containers, setContainers] = useState<IContainer[]>([]);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [containerToDelete, setContainerToDelete] = useState<
    IContainer | undefined
  >(undefined);
  const [previewVehicles, setPreviewVehicles] = useState<IVehicle[] | null>(
    null
  );
  const handleStatusClick = (status: string) => {
    if (status === activeStatus) {
      // 👇️ delete each query param
      searchParams.delete("status");

      // 👇️ update state after
      setSearchParams(searchParams);
      return;
    }
    setSearchParams({ status });
  };
  const user = getCurrentUser();
  const renderVehicles = (vehicles: IVehicle[]) => {
    return vehicles ? (
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPreviewVehicles(vehicles);
        }}
      >
        View vehicles ({vehicles.length})
      </Link>
    ) : (
      0
    );
  };
  const handleOpenContainerDialog = (container?: IContainer) => {
    setOpenContainerDialog(prev => !prev);
    if(container) {
      setContainerToEdit(container);
    } else {
      setContainerToEdit(undefined)
    }
  }

  const handleOpenContainerPreview = (container?: IContainer) => {
    setOpenContainerPreviewDialog(prev => !prev);
    if(container) {
      setContainerToEdit(container);
    } else {
      setContainerToEdit(undefined)
    }
  }
  const actionColumn: GridColDef = {
    field: "date",
    sortable: false,
    // flex: 1,
    width: 130,
    headerAlign: "center",
    align: "center",
    headerName: "Actions",
    renderCell: (params: GridRenderCellParams<Date>) => (
      <ActionMenu>
        <IconButton onClick={() => handleOpenContainerDialog(params.row)}>
          <EditIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={() => handleOpenContainerPreview(params.row)}>
          <VisibilityIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={() => handleDeleteContainer(params.row)}>
          <DeleteForeverIcon color="error" fontSize="medium" />
        </IconButton>
      </ActionMenu>
    ),
  };
  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
    },
    { field: "name", headerName: "Container number", flex: 1 },
    {
      field: "shippingLine",
      align: "left",
      headerAlign: "left",
      headerName: "Shipping line",
      type: "string",
      renderCell: (params: GridRenderCellParams) => {
        return params.value?.name;
      },
      flex: 1,
    },
    { field: "destination", headerName: "Destination", flex: 1 },
    {
      field: "expectedDate",
      headerName: "Expected date",
      flex: 1,
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 150,
      valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "vehicles",
      sortable: false,
      // flex: 1,
      width: 200,
      headerName: "Loaded vehicles",
      cellClassName: "loadedVehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      flex: 1,

      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "Vehicle list",
      valueGetter: (params) => {
        return params.row?.vehicles
          ?.map((vehicle: IVehicle) => vehicle.vin)
          .join(",");
      },
      field: "vehicleList",
    },
    ...(user?.role !== UserRoles.CLIENT ? [actionColumn] : []),
  ];
  const mobileColumns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    { field: "name", headerName: "Container number", width: 150 },
    {
      field: "vehicles",
      sortable: false,
      // flex: 1,
      width: 200,
      headerName: "Loaded vehicles",
      cellClassName: "loadedVehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      flex: 1,

      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    actionColumn,
  ];
  const handleDeleteContainer = (container: IContainer) => {
    setContainerToDelete(container);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (containerToDelete) await deleteContainer(containerToDelete.id);
      toast.success("Container deleted successfully");
    } catch (error) {
      console.error(error, "ERROR");
      toast.error("Something went wrong");
    }
    loadContainers();
    setContainerToDelete(undefined);
    setDeleteOpen(false);
  };
  const activeStatus = searchParams.get("status");
  const loadContainers = async (searchParams?: URLSearchParams) => {
    const { data: result } = await getContainers(activeStatus);
    setContainers(result.data);
  };
  useEffect(() => {
    loadContainers(searchParams);
  }, [searchParams]);

  const statuses = Object.keys(ContainerStatuses).map((key) => {
    return {
      key,
      name: ContainerStatuses[key],
    };
  });
  const submitCallback = () => {
    setOpenContainerDialog(false);
    loadContainers()
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Containers</Typography>
        {user?.isAdmin && (
          <Button variant="contained" onClick={() => handleOpenContainerDialog(undefined)}>
            Add Container
          </Button>
        )}
        {openContainerDialog && (
          <ContainerDialog
            containerId={containerToEdit?.id}
            submitCallback={submitCallback}
            handleToggleOpen={handleOpenContainerDialog}
          />
        )}
        {openContainerPreviewDialog && (
          <ContainerDetails handleToggleOpen={handleOpenContainerPreview} containerData={containerToEdit} />
        )}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="Are you sure to delete this container?"
      >
        Deleting container <strong>{containerToDelete?.name}</strong>
      </ConfirmDialog>
      {previewVehicles && (
        <LoadedVehiclesDialog
          vehicles={previewVehicles}
          //submitCallback={loadVehicles}
          handleToggleOpen={() => {
            setPreviewVehicles(null);
          }}
        />
      )}
      <GridActions>
        <QuickStatusFilter
          activeStatus={activeStatus}
          onClick={handleStatusClick}
          statuses={statuses}
        />
      </GridActions>
      <ContainersGrid>
        <DataGrid
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns createdAt, the other columns will remain visible
                createdAt: isMobile,
                vehicleList: false,
              },
            },
          }}
          disableSelectionOnClick
          rows={containers}
          columns={isMobile ? mobileColumns : columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxSelection
        />
      </ContainersGrid>
    </Box>
  );
};
