import { Button, Grid, ImageList, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import update from "immutability-helper";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactImageGalleryItem } from "react-image-gallery";
import { object, string } from "yup";
import { ShipperSelect } from "../../components/FormElements/ShipperSelect";
import { ShippingLineSelect } from "../../components/FormElements/ShippingLineSelect";
import { TextFieldWrapper } from "../../components/FormElements/TextFieldWrapper";
import { UserSelect } from "../../components/FormElements/UserSelect";
import { VehicleSelect } from "../../components/FormElements/VehicleSelect";
import { IContainer } from "../../services/container-service";
import { ContainerStatuses, UserRoles } from "../../utils";
import { DraggableImage } from "../Vehicles/DraggableImage";
import { getCurrentUser } from "../../services/auth-service";

interface ContainerFormProps {
  containerData?: IContainer;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const ContainerForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  const user = getCurrentUser();
  const [imagesState, setImages] = React.useState<any[]>([]);
  const [imagesToDeleteState, setImagesToDelete] = React.useState<any>([]);
  const [imagesToAdd, setImagesToAdd] = React.useState<any>([]);
  let validationSchema = object({
    name: string().required("Container number is required"),
    warehouseId: string().required("Warehouse is required"),
    destination: string().required("Destination is required"),
    status: string().required("Status is required"),
  });
  const containerValues = {
    expectedDate: dayjs().format("YYYY-MM-DD"),
    status: ContainerStatuses.LOADED,
  } as IContainer;


  const imageObject = (file: any) => {
    return {
      name: file.name ? file.name : file,
      original: typeof file === "object" ? URL.createObjectURL(file) : file,
      thumbnail: typeof file === "object" ? URL.createObjectURL(file) : file,
    };
  };
  const handleMultipleImages = React.useCallback(
    (targetFiles: any) => {
      const selectedFiles: ReactImageGalleryItem[] = [];
      const images: any = [];
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > 5000) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        images.push(file);
        return selectedFiles.push(imageObject(file));
      });
      setImages(imagesState.concat(selectedFiles));
      setImagesToAdd(imagesToAdd.concat(images));
    },
    [imagesState, imagesToAdd]
  );

  const handleRemoveImage = (
    imageToDelete: any,
    images: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setImages((current) => current.filter((image) => imageToDelete !== image));
    setFieldValue(
      "images",
      images?.filter((image: any) => {
        return image !== imageToDelete;
      })
    );
    if (containerData) {
      setImagesToDelete(
        imagesToDeleteState.concat(imageToDelete.name.split("%2F").pop())
      );
    }
  };

  const moveImage = React.useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      setFieldValue: (field: string, value: any) => void
    ) => {
      setImages((prevImages) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
      setImagesToAdd((prevImages: any) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
    },
    []
  );
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={
        { ...containerData, warehouseId: containerData?.warehouse?._id } ||
        containerValues
      }
      onSubmit={async (values: any) => {
        handleSubmit({
          ...values,
          imagesToAdd: imagesToAdd,
          imagesToDelete: imagesToDeleteState,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (

        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                required
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label="Container number"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="destination"
                name="destination"
                label="Destination"
                value={values.destination}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.destination)}
                helperText={errors.destination}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ShippingLineSelect
                handleChange={(shippingLine) =>
                  setFieldValue("shippingLineId", shippingLine?.id)
                }
                value={containerData?.shippingLine}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                error={touched.status && Boolean(errors.status)}
                helperText={touched.status && errors.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {Object.keys(ContainerStatuses).map((key) => {
                  return (
                    <MenuItem id={key} key={key} value={key}>
                      {ContainerStatuses[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <ShipperSelect
                handleChange={(shipper) =>
                  setFieldValue("shipperId", shipper?.id)
                }
                value={values?.shipper}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    label="Expected date"
                    inputFormat="DD/MM/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label="Expected date"
                    inputFormat="MM/DD/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={12}>
              <VehicleSelect
                useAsyncOptions
                handleChange={setFieldValue}
                initialValues={values.vehicles ? [...values.vehicles] : []}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UserSelect
                disabled={!user?.isAdmin}
                role={[UserRoles.WAREHOUSE]}
                label="Receiver Warehouse"
                handleChange={(user) => setFieldValue("warehouseId", user?.id)}
                values={values?.warehouse}
                error={Boolean(errors.warehouseId)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldWrapper
                multiline
                size="small"
                fullWidth
                id="notes"
                inputProps={{
                  maxLength: 300,
                }}
                name="notes"
                label="Notes"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextFieldWrapper>
            </Grid>
            <Grid container item>
            <Grid item xs={12} md={6}>
              <Button variant="contained" component="label">
                Upload images
                <input
                  hidden
                  id="images"
                  name="images"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(event) => {
                    const files = event.target.files;
                    handleMultipleImages(files);
                    if (files) {
                      let myFiles = Array.from(files);
                      setFieldValue("images", myFiles);
                        // set status to DELIVERED if images are selected
                        setFieldValue("status", "DELIVERED");
                    }
                    
                  }}
                  multiple
                />
              </Button>
            </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <GoogleDriveGallery /> */}
              <ImageList variant="quilted" cols={4} rowHeight={120}>
                <DndProvider backend={HTML5Backend}>
                  {imagesState?.map((item, index) => (
                    <DraggableImage
                      id={index}
                      moveCard={(dragIndex: number, hoverIndex: number) =>
                        moveImage(dragIndex, hoverIndex, setFieldValue)
                      }
                      removeImage={(imageToDelete) =>
                        handleRemoveImage(
                          imageToDelete,
                          values?.images,
                          setFieldValue
                        )
                      }
                      image={item}
                      index={index}
                    />
                  ))}
                </DndProvider>
              </ImageList>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
