import styled from 'styled-components';
import { IAuction } from '../services/auction-service';
import { toast } from 'react-hot-toast';
import PDFMerger from 'pdf-merger-js';
import { IVehicle } from '../services/vehicle-service';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { isIOS } from 'react-device-detect';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const mobileVersionWidth = 900;
export const FinalDestinations: Record<string, any> = {
  'Sofia':'Sofia',
  'Plovdiv':'Plovdiv',
  'Vratsa':'Vratsa',
  'Burgas':'Burgas',
  'Pazar':'Novi pazar (Shumen)',
}
export enum VehicleStatus {
  TRANSIT ='Dispatched',
  AT_TERMINAL ='At Terminal',
  LOADED = 'Loaded container',
  UNLOADED = 'Customs',
  TRANSHIPMENT = 'Transhipment',
  LOADED_ON_THE_TRUCK = 'Loaded truck',
  DELIVERED = 'Delivered',
}
export const VehicleTypes: Record<string, any> = {
  'CAR':'Car',
  'JET':'Jet',
  'MOTORBIKE':'Motorbike',
  'ATV':'ATV/UTV',
}
export const ContainerStatuses: Record<string, any> = {
  "LOADED": "LOADED",
  "CUSTOMS": "CUSTOMS",
  "TRANSHIPMENT" : "TRANSHIPMENT",
  "DELIVERED" : "DELIVERED",
}
export const TruckStatuses: Record<string, any> = {
    "ASSIGNED" : "ASSIGNED",
    "DELIVERED" : "DELIVERED",
}
export enum UserRoles {
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  WAREHOUSE = 'WAREHOUSE',
}
export const GridActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const isValidDate = (d: any) =>  {
  return d instanceof Date;
}
export const deleteDocument = (
  documents: File[],
  documentName: string,
  setFieldValue: (field: string, value: any) => void
) => {
  const filteredDocs = documents.filter(function (obj) {
    return obj.name !== documentName;
  });
  setFieldValue("documents", filteredDocs);
};
export const mapImagesToGalleryItems = (images: string[]) : ReactImageGalleryItem[] => {
  return images?.map((file) => {
    return {
      original: file,
      thumbnail: file,
      sizes: "278",
      loading: "eager"
    };
  });
}
export const decodeVin = (vin: string) => {
  window.open(`https://www.vindecoder.pl/${vin}`, "_blank");
};
export const trackContainer = (trackingUrl?: string, containerNumber?: string) => {
  if(!trackingUrl) {
    return;
  }
  window.open(`${trackingUrl}${containerNumber}`, "_blank");
};
export const viewStock = (stock: string, auction?: IAuction) => {
  if(!auction || !auction?.trackingUrl)
  return;
  let {trackingUrl} = auction;
  if(!/(http(s?)):\/\//i.test(trackingUrl)) {
    trackingUrl = 'https://' + trackingUrl;
  }
  window.open(`${trackingUrl}${stock}`, "_blank");
};
export const copyText = (stringToCopy: string, successMesssage: string) => {
    navigator.clipboard.writeText(stringToCopy);
    toast.success(successMesssage);
};
export const downloadDocuments = async (vehicleData: IVehicle) => {
  
  const { documents, vin } = vehicleData;
  const merger = new PDFMerger();
  if (documents.length === 0) return;

  if (isIOS) {
    if (documents.length === 0) return;

    const zip = new JSZip();
    const folder = zip.folder(vin.slice(-6)); // folder name where all files will be placed in

    documents?.forEach((document: any) => {
      const url = document;
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = url.substring(url.lastIndexOf("/") + 1).split("%2F").pop()?.replace("%", " ");
      folder?.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, vin.slice(-6)));
    return;
  }
  for (const document of documents) {
    await merger.add(document);
  }
  await merger.save(vin.slice(-6));
  };
