import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { TextFieldWrapper } from "./TextFieldWrapper";
import { IShippingLine, getShippingLines } from "../../services/shipping-line-service";
interface UserSelectProps {
  handleChange?: (selectedAuction: IShippingLine | null) => void;
  children?: React.ReactNode;
  title?: string;
  value?: IShippingLine | null;
  error?: boolean;
  name?: string;
  label?: string;
}
export const ShippingLineSelect = React.memo(function Images(props: UserSelectProps) {
  return <ShippingLineSelectInput {...props} />
});
export const ShippingLineSelectInput: React.FC<UserSelectProps> = ({
  error,
  name,
  handleChange,
  value,
  label,
}) => {
  const [auctions, setAuctions] = useState<IShippingLine[]>([]);
  const [auctionId, setSelectedAuction] = useState<IShippingLine | null>(null);
  const [open, setOpen] = useState(false);
  const loadAuctions = useCallback(async () => {
    const {data: shippingLines} = await getShippingLines();
    setAuctions(shippingLines.data)
  }, [])
  useEffect(() => {
    loadAuctions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(value)
    setSelectedAuction(value);
  }, [value])
  const onSelectAuction = (event: React.SyntheticEvent, value: IShippingLine | null) => {
    if(value)
    setSelectedAuction(value)
    setOpen(false)
    if(handleChange)
    handleChange(value);
  }
  const handleOpen = () => {
    setOpen(true);

  }
  return (
    <Autocomplete
      open={open}
      size="small"
      value={auctionId}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={handleOpen}
      id="checkboxes-tags-demo"
      options={auctions}
      disableCloseOnSelect
      onChange={onSelectAuction}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextFieldWrapper {...params} label="Select shipping line" placeholder="Search shipping line" />
      )}
    />
  );
};
