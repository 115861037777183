import * as React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
`;
export const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SettingsIcon style={{ color: "#fff" }} fontSize="medium" />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <StyledNavLink to="/settings">
          <MenuItem onClick={handleClose}>Settings</MenuItem>
        </StyledNavLink>
        <StyledNavLink to="/users">
          <MenuItem onClick={handleClose}>Users</MenuItem>
        </StyledNavLink>
        <StyledNavLink to="/auctions">
          <MenuItem onClick={handleClose}>Auctions</MenuItem>
        </StyledNavLink>
        {/* <StyledNavLink to="/truck-companies">
          <MenuItem onClick={handleClose}>Truck companies</MenuItem>
        </StyledNavLink> */}
        <StyledNavLink to="/shipping-lines">
          <MenuItem onClick={handleClose}>Shipping lines</MenuItem>
        </StyledNavLink>
        {/* <StyledNavLink to="/shippers">
          <MenuItem onClick={handleClose}>Shippers</MenuItem>
        </StyledNavLink>
        <StyledNavLink to="/truck-companies">
          <MenuItem onClick={handleClose}>Truck companies</MenuItem>
        </StyledNavLink> */}
        {/* <StyledNavLink to="/truck-companies">
          <MenuItem onClick={handleClose}>Loading terminals</MenuItem>
        </StyledNavLink> */}
      </Menu>
    </>
  );
};
