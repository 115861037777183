import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";

import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { IconButton } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";

export const ItemTypes = {
  CARD: "card",
};
export interface DraggableImageProps {
  id: any;
  index: number;
  image: any;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeImage: (imageToDelete: any) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
const MainImagePlaceholder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  text-align: center;
  color: #fff;
  background: ${({ theme }) => theme.blueBackground};
`;
const VisibleHover = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  position: absolute;
`;
const ImageListItemWithStyle = styled(ImageListItem)`
  &:hover ${VisibleHover} {
    display: block;
  }
`;
export const DraggableImage: FC<DraggableImageProps> = ({
  id,
  image,
  index,
  moveCard,
  removeImage,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <div ref={ref} data-handler-id={handlerId}>
      <ImageListItemWithStyle key={image.img}>
        <img
          src={`${image.original}`}
          srcSet={`${image.original}`}
          alt={image.title}
          loading="lazy"
        />
        {index === 0 && <MainImagePlaceholder>Main</MainImagePlaceholder>}
        <VisibleHover>
          <IconButton
            onClick={() => {
              removeImage(image);
            }}
          >
            <DeleteRoundedIcon
              sx={{
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
              color="error"
            ></DeleteRoundedIcon>
          </IconButton>
        </VisibleHover>
      </ImageListItemWithStyle>
    </div>
  );
};
