import React, { useEffect, useState } from "react";
  
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import styled from "styled-components";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { getCurrentUser } from "../../services/auth-service";
import {
  ClaimStatus,
  deleteClaim,
  getClaims,
  IClaim,
  IVehicle
} from "../../services/vehicle-service";
import { GridActions } from "../../utils";
import { ClaimDialog } from "./ClaimDialog";
import { ClaimPreview } from "./ClaimPreview";

const ContainersGrid = styled.div`
   {
    height: 500px;
    width: 100%;
    .loadedVehicles {
      justify-content: center !important;
      flex-direction: column;
      p {
        width: 100%;
      }
    }
  }
`;
  interface VehicleFormProps {
    vehicleData?: IVehicle;
  }
  export const Claims: React.FC<VehicleFormProps> = ({
    vehicleData,
  }) => {
      const [loading, setLoading] = useState(false)
      const [openDialog, setOpenDialog] = useState(false);
      const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
      const [claimToDelete, setClaimToDelete] = useState<IClaim | undefined>(undefined)
      const [claims, setClaims] = useState<any>([]);
      const [claimToEdit, setClaimToEdit] = useState<IClaim | undefined>(undefined);
      const loadClaims = async () => {
        setLoading(true);
        const { data: claimsData } = await getClaims(vehicleData?.id);
        setClaims(claimsData.data);
        setLoading(false);
        setOpenDialog(false);
        setClaimToEdit(undefined);
      };

    useEffect(() => {
        loadClaims()
    }, [])
    const user = getCurrentUser();
    const handleClaimEdit = (claim: IClaim) => {
      setClaimToEdit(claim)
      setOpenDialog(true);
    }

    const handleClaimPreview = (claim: IClaim) => {
      setClaimToEdit(claim)
      setOpenPreviewDialog(true);
    }
    const actionColumn: GridColDef = {
        field: "date",
        sortable: false,
        // flex: 1,
        width: 130,
        headerAlign: "center",
        align: "center",
        headerName: "Actions",
        renderCell: (params: GridRenderCellParams<Date>) => (
          <ActionMenu>
            <IconButton onClick={() => handleClaimEdit(params.row)}>
              <EditIcon fontSize="medium" />
            </IconButton>
            <IconButton onClick={() => handleClaimPreview(params.row)}>
              <VisibilityIcon fontSize="medium" />
            </IconButton>
            <IconButton onClick={() => setClaimToDelete(params.row)}>
              <DeleteForeverIcon color="error" fontSize="medium" />
            </IconButton>
          </ActionMenu>
        ),
      };
    const columns: GridColDef[] = [
        {
          field: "vin",
          sortable: false,
          // flex: 1,
          width: 200,
          headerName: "VIN",
          valueGetter: (params) => params.row.vehicleId?.vin,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.vehicleId?.vin
          },
        },
        {
          field: "description",
          align: "left",
          headerAlign: "left",
          headerName: "Description",
          type: "string",
          flex: 1,
        },
        {
            field: "status",
            align: "left",
            headerAlign: "left",
            headerName: "Status",
            type: "string",
            flex: 1,
          },
        {
          field: "creationDate",
          headerName: "Date created",
          flex: 1,
          sortable: true,
          width: 150,
          valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
        },
        ...(user?.isAdmin ? [actionColumn] : []),
      ];
    const handleDialogForm = () => {

    if(claims[0] && claims[0].status === ClaimStatus.NEW && vehicleData) {
      toast.error(`You already have opened claim for ${vehicleData.description} ${vehicleData.vin}`);
    }
      setOpenDialog(prev => !prev);
    }

    const handlePreviewDialog = () => {
      setOpenPreviewDialog(prev => !prev);
    }

    const handleConfirmDelete = async () => {
      try {
        if (claimToDelete) await deleteClaim(claimToDelete._id);
        toast.success("Claim deleted successfully");
        loadClaims();
        setClaimToDelete(undefined);
      } catch (error) {
        console.log(error, "ERROR");
        toast.error("Something went wrong");
      }

    };

    const submitCallback = () => {
      loadClaims();
      setClaimToEdit(undefined);
    }
    return (
        <>
        
            <GridActions>
              
                {!vehicleData && <Typography variant="h4">Claims</Typography>}
                <div
                    style={{
                        justifyContent: vehicleData ? "flex-end" : "",
                        display: "flex",
                        gap: 5,
                        width: vehicleData ? "100%" : "",
                    }}
                >
                    <Button variant="contained" onClick={handleDialogForm}>
                        Add Claim
                    </Button>
                </div>
            </GridActions>
          <ContainersGrid>
                <DataGrid
                    getRowId={(row: any) => row._id}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "createdAt", sort: "desc" }],
                        },
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns createdAt, the other columns will remain visible
                                vehicleList: false,
                            },
                        },
                    }}
                    disableColumnFilter
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    disableSelectionOnClick
                    rows={claims || []}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    checkboxSelection />
            </ContainersGrid>
            {claimToDelete && (
              <ConfirmDialog
              handleConfirm={handleConfirmDelete}
              handleClose={() => setClaimToDelete(undefined)}
              open
              title="Are you sure to delete this claim?"
            >
            </ConfirmDialog>
            )}
            {openDialog && <ClaimDialog claimData={claimToEdit} vehicleData={vehicleData} submitCallback={submitCallback} handleToggleOpen={handleDialogForm} />}
            {openPreviewDialog && <ClaimPreview claimData={claimToEdit} handleToggleOpen={handlePreviewDialog} />}
        </>
    );
  };
  