import { Checkbox, FormControlLabel, Grid, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { IUser } from "../../services/user-service";
import { UserRoles } from "../../utils";

interface TruckFormProps {
  userData?: IUser;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const UserForm: React.FC<TruckFormProps> = ({
  formRef,
  userData,
  handleSubmit,
}) => {
  let validationSchema = object({
    email: string()
      .required("E-mail is required")
      .email("Must be a valid e-mail"),
    password: userData
      ? string()
      : string()
          .required("Password is required and minimum 6 symbols in length")
          .min(6),
    firstName: string().required("Name is required"),
  });
  const userValues: any = {
    id: "",
  };
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={userData || userValues}
      onSubmit={async (values: IUser) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="nickname"
                name="nickname"
                label="Nickname"
                required
                value={values.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="firstName"
                name="firstName"
                required
                label="Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="password"
                required
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>

            {/* 
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="lastName"
                name="lastName"
                label="Last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="role"
                name="role"
                label="Role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {(Object.keys(UserRoles) as Array<keyof typeof UserRoles>).map(
                  (key) => {
                    return (
                      <MenuItem id={key} key={key} value={key}>
                        {UserRoles[key]}
                      </MenuItem>
                    );
                  }
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                id="email"
                name="email"
                label="E-mail"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="phone"
                name="phone"
                label="Phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="balanceSheetUrl"
                name="balanceSheetUrl"
                label="Balance sheet url"
                value={values.balanceSheetUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.balanceSheetUrl && Boolean(errors.balanceSheetUrl)
                }
                helperText={touched.balanceSheetUrl && errors.balanceSheetUrl}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="econtOffice"
                name="econtOffice"
                label="Econt"
                value={values.econtOffice}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("allowBranding", event.target.checked);
                    }}
                    checked={values.allowBranding}
                  />
                }
                label="Allow branding"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
