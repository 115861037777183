import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import axiosIntance from "../axiosInstance";
import axiosInstanceFiles from "../axiosInstanceFiles";
import { VehicleStatus } from "../utils";
import { IAuction } from "./auction-service";
import { IContainer } from "./container-service";
import { IUser } from "./user-service";
interface ISearchVehicle {
  count: number,
  data: IVehicle[]
}
export interface StatusHistory {
  date: string,
  status: keyof typeof VehicleStatus,
  note?: string
}
export interface IVehicle {
    id: string,
    _id: string,
    vin: string,
    status: keyof typeof VehicleStatus,
    type: keyof typeof VehicleStatus,
    stockNumber: string,
    user: IUser,
    documents?: any,
    warehouse?: IUser,
    images?: any,
    destination?: string,
    finalDestination?: string,
    expectedDate?: string,
    titleSentDate?: string,
    containerId?: any;
    containerName?: string,
    container?: IContainer | null,
    auctionLocation?: string,
    statusHistory: StatusHistory[],
    notes?: string,
    auction?: IAuction,
    keys: boolean,
    paid: boolean,
    titleTrackingNumber?: string,
    description: string,
    createdBy?: string,
    createdAt: string,
    updatedAt: string
}

export enum ClaimStatus {
  NEW = "NEW",
  IN_PROGRESS = "IN PROGRESS",
  RESOLVED = "RESOLVED",
  CLOSED = "CLOSED"
}

export interface IClaim {
  id: string,
  _id: string,
  description: string,
  images?: any,
  vin: string,
  status: ClaimStatus,
  imagesToAdd?: any,
  vehicleId: IVehicle,
  createdAt: string,
  updatedAt: string
}

export interface ICreateClaim {
  vin: string;
  status?: string;
  description?: any,
  vehicleId: string
}
export interface ICreateVehicle extends IVehicle {
  userId?: string,
  warehouseId?: string,
  auctionId?: string,
  containerId?: string | null
}
export interface IVehicleImageObject {
  imagesToAdd?: any,
  imagesToDelete?: any,
}
export interface IVehicleDocumentObject {
  documentsToAdd?: any,
  documentsToDelete?: any,
}

export interface ISignalMessage {
  sender: string,
  recipients: string[],
  message: string;
}
interface IVehicles {
  count: number,
  data: IVehicle[]
}

interface IClaims {
  count: number,
  data: IVehicle[]
}

export interface IDateRange {start: string; end: string};
export const getVehicle = (vehicleId: string) => {
  return axiosIntance.get<IVehicle>(`/vehicles/${vehicleId}`);
};
export const createVehicle = (values: ICreateVehicle) => {
  return axiosIntance.post<IVehicle>('/vehicles', values);
}
export const updateVehicle = (values: ICreateVehicle) => {
  return axiosIntance.put<IVehicle>(`/vehicles/${values.id}`, values);
}
export const searchVehicle = (vin?: string, useStockNumber?: boolean) => {
  return axiosIntance.get<IVehicle>(`/search`, {
    params: {
      vin: useStockNumber ? undefined : vin,
      stockNumber: useStockNumber ? vin : undefined,
    }
  });
};
export const decodeVinNhtsa = (vin?: string) => {
  return axios.get<any>(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`, {
  });
};
export const searchEvents = (dateRange: IDateRange) => {
  return axiosIntance.get(`/search/events?startDate=${dateRange.start}&endDate=${dateRange.end}`);
}
export const getVehicles = (status?: keyof typeof VehicleStatus) => {
  return axiosIntance.get<IVehicles>('/vehicles',{
    params: {
      // page: page + 1,
      // pageSize,
      status
    }
});
};
export const vehiclesSearch = () => {
  return axiosIntance.get<IVehicle[]>('/vehicles/search');
};
export const deleteVehicle = (vehicleId: string) => {
    return axiosIntance.delete<IVehicle>(`/vehicles/${vehicleId}`);
  };
export const uploadVehicleDocuments = (vehicleId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/documents/vehicle/${vehicleId}`, formData);
};
export const uploadVehicleImages = (vehicleId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/images/vehicle/${vehicleId}`, formData);
};
export const deleteVehicleImages = (vehicleId: string, imagesToDelete: string[]) => {
  return axiosIntance.put(`/files/remove/vehicles/${vehicleId}/images`, {files: imagesToDelete});
};
export const deleteVehicleDocuments = (vehicleId: string, documentsToDelete: string[]) => {
  return axiosIntance.put(`/files/remove/vehicles/${vehicleId}/documents`, {files: documentsToDelete});
};
export const bulkUpdateVehicles = (vehicleIds: string[], status: VehicleStatus) => {
  return axiosIntance.put(`/vehicles/bulk/updata/status`, {vehicleIds, status});
};
export const sendSignalMessage = (signalMessage: ISignalMessage) => {
  return axiosIntance.post(`/vehicles/send-signal-message`, signalMessage);
};
export const searchVehicleInput = (vin?: string, status?: string) => {
  return axiosIntance.get<ISearchVehicle>(`/vehicles/search`, {
      params: {
        page: 1,
        pageSize: 10,
        vin,
        status
      }
  });
};
export const createVehicleMethod = async (values: ICreateVehicle & IVehicleImageObject) => {
  const validDate = dayjs(values.expectedDate).isValid();
  const validSent = dayjs(values.titleSentDate).isValid();
  const valuesMod: ICreateVehicle = {
      ...values,
      documents: undefined,
      images: undefined,
      expectedDate: validDate ? values.expectedDate : undefined,
      titleSentDate: validSent ? values.titleSentDate : undefined,
  };
  
      const createdVehicle = await createVehicle(valuesMod);
      const vehicleData = createdVehicle.data;
      if (values.imagesToAdd && values.imagesToAdd.length > 0) {
        
          const formData = new FormData();
          for (let i = 0; i < values.imagesToAdd.length; i++) {
              formData.append("images", values.imagesToAdd[i]);
          }
          await uploadVehicleImagesMethod(vehicleData.id, formData);
      }
      if (values.documents && values.documents.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < values.documents.length; i++) {
              formData.append("documents", values.documents[i]);
          }
         await uploadVehicleDocumentsMethod(vehicleData.id, formData);
  }
  return createdVehicle;
}
export const updateVehicleMethod = async (vehicleId: string, values: ICreateVehicle & IVehicleImageObject & IVehicleDocumentObject) => {
  const validDate = dayjs(values.expectedDate).isValid();
  const validSent = dayjs(values.titleSentDate).isValid();
  const valuesMod: ICreateVehicle = {
      ...values,
      documents: undefined,
      images: undefined,
      expectedDate: validDate ? values.expectedDate : undefined,
      titleSentDate: validSent ? values.titleSentDate : undefined
  };

  const updatedVehicle = await updateVehicle(valuesMod);
      const { id } = updatedVehicle.data;
      if (values.imagesToAdd && values.imagesToAdd.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < values.imagesToAdd.length; i++) {
              formData.append("images", values.imagesToAdd[i]);
        }
          await uploadVehicleImagesMethod(id, formData);
      }
      if (values.documents && values.documents.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < values.documents.length; i++) {
              formData.append("documents", values.documents[i]);
          }
          await uploadVehicleDocumentsMethod(id, formData);
         
      }
      if(values.imagesToDelete && values.imagesToDelete.length > 0) {
        await deleteVehicleImages(id, values.imagesToDelete)
      }
      if(values.documentsToDelete && values.documentsToDelete.length > 0) {
        await deleteVehicleDocuments(id, values.documentsToDelete)
      }
}

export const getClaims = (vehicleIds?: string) => {
  if(!vehicleIds) {
    return axiosIntance.get<IClaims>(`/claim`); 
  }
  return axiosIntance.get<IClaims>(`/claim`,{
    params: {
      vehicleIds
    }
  });
};

export const createClaim = (values: ICreateClaim) => {
  return axiosIntance.post<IClaim>(`/claim`, values);
};

export const updateClaim = (values: any) => {
  return axiosIntance.put<IClaim>(`/claim/${values._id}`, values);
};

export const deleteClaim = (claimId: string) => {
  return axiosIntance.delete<IVehicle>(`/claim/${claimId}`);
};

export const uploadClaimImages = (vehicleId: string, formData: any) => {
  console.log(formData, "FORM DATA")
  return axiosInstanceFiles.post(`/files/upload/images/claim/${vehicleId}`, formData);
};

export const createClaimMethod = async (values: any) => {
  const createdClaim = await createClaim(values);
  const claimData = createdClaim.data;
  if (values.imagesToAdd && values.imagesToAdd.length > 0) {
    
      const formData = new FormData();
      for (let i = 0; i < values.imagesToAdd.length; i++) {
          formData.append("images", values.imagesToAdd[i]);
      }
      await uploadClaimImagesMethod(claimData._id, formData);
  }
}

export const updateClaimMethod = async (values: any) => {
  const createdClaim = await updateClaim(values);
  const claimData = createdClaim.data;
  console.log(claimData, "CLAIM DATA")
  if (values.imagesToAdd && values.imagesToAdd.length > 0) {
    
      const formData = new FormData();
      for (let i = 0; i < values.imagesToAdd.length; i++) {
        console.log(values.imagesToAdd[i], "VALUES TO ADD IMAGES")
          formData.append("images", values.imagesToAdd[i]);
      }
      console.log(formData.get("images"), "FORM DATA")
      await uploadClaimImagesMethod(values._id, formData);
  }
}

const uploadClaimImagesMethod = async (claimId: string, formData: FormData) => {
  toast.promise(uploadClaimImages(claimId, formData), {
    loading: "Loading images",
    success: "Images uploaded",
    error: "Error when uploading images",
});
}
const uploadVehicleImagesMethod = async (vehicleId: string, formData: FormData) => {
  toast.promise(uploadVehicleImages(vehicleId, formData), {
    loading: "Loading images",
    success: "Images uploaded",
    error: "Error when uploading images",
});
}
const uploadVehicleDocumentsMethod = async (vehicleId: string, formData: FormData) => {
  toast.promise(uploadVehicleDocuments(vehicleId, formData), {
    loading: "Loading documents",
    success: "Documents uploaded",
    error: "Error when uploading documents",
  });
}