import {
  Button,
  Grid,
  ImageList,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import update from "immutability-helper";
import React, { useCallback, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactImageGalleryItem } from "react-image-gallery";
import { object, string } from "yup";
import { VehicleSelect } from "../../components/FormElements/VehicleSelect";
import { getCurrentUser } from "../../services/auth-service";
import { ClaimStatus, IClaim, IVehicle } from "../../services/vehicle-service";
import { DraggableImage } from "./DraggableImage";

interface VehicleFormProps {
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
  vehicleData?: IVehicle;
  claimData?: IClaim
}
const MAX_FILE_SIZE = 5096; // 3MB
export const ClaimForm: React.FC<VehicleFormProps> = ({
  formRef,
  vehicleData,
  claimData,
  handleSubmit,
}) => {
  const user = getCurrentUser();
  const [imagesState, setImages] = React.useState<any[]>([]);
  const [imagesToDeleteState, setImagesToDelete] = React.useState<any>([]);
  const [imagesToAdd, setImagesToAdd] = React.useState<any>([]);
  const imageObject = (file: any) => {
    return {
      name: file.name ? file.name : file,
      original: typeof file === "object" ? URL.createObjectURL(file) : file,
      thumbnail: typeof file === "object" ? URL.createObjectURL(file) : file,
    };
  };
  const handleMultipleImages = useCallback(
    (targetFiles: any) => {
      const selectedFiles: ReactImageGalleryItem[] = [];
      const images: any = [];
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        images.push(file);
        return selectedFiles.push(imageObject(file));
      });
      setImages(imagesState.concat(selectedFiles));
      setImagesToAdd(imagesToAdd.concat(images));
    },
    [imagesState, imagesToAdd]
  );
  const moveImage = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      setFieldValue: (field: string, value: any) => void
    ) => {
      setImages((prevImages) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
      setImagesToAdd((prevImages: any) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
    },
    []
  );
  useEffect(() => {
    if (claimData?.images)
      setImages([
        ...claimData.images.map((image: any) => {
          return imageObject(image);
        }),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimData]);
  let validationSchema = object({
    description: string().required("Description is required"),
  });
  const handleRemoveImage = (
    imageToDelete: any,
    images: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setImages((current) => current.filter((image) => imageToDelete !== image));
    setFieldValue(
      "images",
      images?.filter((image: any) => {
        return image !== imageToDelete;
      })
    );
    if (vehicleData) {
      setImagesToDelete(
        imagesToDeleteState.concat(imageToDelete.name.split("%2F").pop())
      );
    }
  };
  // @ts-ignore
  const vehicleValues = {
    vehicleId: vehicleData,
    status: claimData?.status || ClaimStatus.NEW,
    description: claimData?.description
  } as any;
  const initialData = {
    ...vehicleValues,
    images: null
  }
  return (
    <Formik
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={claimData || initialData}
      onSubmit={async (values: any) => {
        handleSubmit({
          ...values,
          imagesToAdd: imagesToAdd,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {vehicleData ? <TextField
                disabled
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="vehicle"
                name="vehicle"
                label="Vehicle"
                value={`${vehicleData.description + " - " + vehicleData.vin}`}
              />: 
              <VehicleSelect handleChange={setFieldValue} multiple={false} fieldName="vehicleId" initialValues={values.vehicleId ? values.vehicleId : {}} />
            }
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="description"
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                // helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!user?.isAdmin}
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.status && Boolean(errors.status)}
                // helperText={touched.name && errors.name}
                >
                            {(
                              Object.keys(ClaimStatus) as Array<
                                keyof typeof ClaimStatus
                              >
                            ).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {ClaimStatus[key]}
                                </MenuItem>
                              );
                            })}
              </TextField>
            </Grid>
            <Grid container item xs={12} md={6}>
              <Button variant="contained" component="label">
                Upload images
                <input
                  hidden
                  id="images"
                  name="images"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(event) => {
                    const files = event.target.files;
                    handleMultipleImages(files);
                    if (files) {
                      let myFiles = Array.from(files);
                      setFieldValue("images", myFiles);
                    }
                  }}
                  multiple
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageList variant="quilted" cols={4} rowHeight={120}>
                <DndProvider backend={HTML5Backend}>
                  {imagesState?.map((item, index) => (
                    <DraggableImage
                      id={index}
                      moveCard={(dragIndex: number, hoverIndex: number) =>
                        moveImage(dragIndex, hoverIndex, setFieldValue)
                      }
                      removeImage={(imageToDelete) =>
                        handleRemoveImage(
                          imageToDelete,
                          values?.images,
                          setFieldValue
                        )
                      }
                      image={item}
                      index={index}
                    />
                  ))}
                </DndProvider>
              </ImageList>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
