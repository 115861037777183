import * as React from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { mobileVersionWidth } from "../../utils";

const QuickStatusFilterContainer = styled.div`
    display:flex;
    gap: 7px;
    
    @media screen and (max-width: ${mobileVersionWidth}px) {
      padding-bottom: 20px;
      overflow-x: scroll;
    }
`
interface IQuickStatusFilterProps {
  activeStatus?: string | null
  statuses: any
  onClick: (status: string) => void;
}
export const QuickStatusFilter: React.FC<IQuickStatusFilterProps> = ({
  activeStatus,
  statuses,
  onClick,
}) => {
  return (
    <QuickStatusFilterContainer>
      {statuses &&
        statuses.map((status: any) => {
          return (
            <Chip
              color="primary"
              clickable
              onClick={() => onClick(status.key)}
              label={status.name}
              variant={activeStatus === status.key ? "filled" : "outlined"}
            />
          );
        })}
    </QuickStatusFilterContainer>
  );
};
