import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { CommonDialog } from "../../components/CommonDialog";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import {
  createClaimMethod,
  IClaim,
  IVehicle,
  updateClaimMethod
} from "../../services/vehicle-service";
import { ClaimForm } from "./ClaimForm";
interface IVehicleDetailsProps {
  vehicleData?: IVehicle;
  claimData?: IClaim
  handleToggleOpen: () => void;
  submitCallback?: () => void;
}
export const ClaimDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicleData,
  claimData
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);

  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };

  const handleSubmit = async (values: IClaim) => {
    const valuesMod = {
      ...values,
      vehicleId: values?.vehicleId.id || values?.vehicleId._id,
      images: undefined,
    }
    try {
      if(claimData?._id) {
        await updateClaimMethod(valuesMod).catch((error: any) => {
          const { response } = error;
          return response;
        });
        toast.success(`Claim updated successfully!`);
      } else {
        await createClaimMethod(valuesMod).catch((error: any) => {
          const { response } = error;
          return response;
        });
        toast.success(`Claim created successfully!`);
        
      }
      if (submitCallback) submitCallback();
    } catch(e) {
      toast.error("SOMETHING WENT WRONG")
      console.log(e, "ERROR")
    }


  };
  const dialogClose = () => {
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        title={claimData?._id ? 'Edit claim' : 'Add claim'}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
          <ClaimForm formRef={formRef} claimData={claimData} handleSubmit={handleSubmit} vehicleData={vehicleData} />
      </CommonDialog>
      <ConfirmDialog
        title="Changes are unsaved!"
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        Some of your changes are not saved, do you really want to close the
        form?
      </ConfirmDialog>
    </>
  );
};
