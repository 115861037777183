import React from "react";
import { CommonDialog } from "../../components/CommonDialog";
import Typography from "@mui/material/Typography";
import { IVehicle } from "../../services/vehicle-service";
import { Link } from "@mui/material";

interface IVehicleDetailsProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  vehicles: IVehicle[];
  title?: string;
}
export const LoadedVehiclesDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicles,
  title,
}) => {
  return (
    <CommonDialog
      maxWidth="sm"
      title={title || "Loaded vehicles"}
      open
      // handleSubmit={handleSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {vehicles.map((vehicle: IVehicle, index: number) => {
        return (
          <Typography>
            <Link target="_blank" href={`/vehicles/view/${vehicle._id}`}>
              {`${index + 1}. ${vehicle.description} - ${vehicle.vin}`}
            </Link>
          </Typography>
        );
      })}
    </CommonDialog>
  );
};
