import axios from 'axios';
import { logout } from './services/auth-service';
const baseURL = 'https://api.autosrealm.com/';
const accessToken = localStorage.getItem("accessToken");
const axiosIntance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + accessToken,
    },
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            console.log(typeof data, "TYPE OF DATA");
            if(data)
            return JSON.parse(data || '');
        },
    ],
});
axiosIntance.interceptors.response.use(
    res => res,
    function (error) {
        if (error?.response?.status === 401) {
            logout();
        }
    return Promise.reject(error)
    }
);
export default axiosIntance;