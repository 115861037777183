import * as React from "react";
import { object, string } from "yup";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { Grid, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { IAuction } from "../../services/auction-service";

interface ContainerFormProps {
  containerData?: IAuction;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
const VehicleSelectLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const AuctionForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  let validationSchema = object({
    name: string().required("Auction name is required"),
  });
  const userValues = {
    name: "",
  } as IAuction;
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={containerData || userValues}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="trackingUrl"
                name="trackingUrl"
                label={
                  <VehicleSelectLabel>
                    <span>Tracking url</span>{" "}
                    <Tooltip title={`Full auction tracking url with trailing slashes included`}>
                      <InfoIcon></InfoIcon>
                    </Tooltip>
                  </VehicleSelectLabel>
                }
                // label="Tracking url"
                value={values.trackingUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.trackingUrl && Boolean(errors.trackingUrl)}
                helperText={touched.trackingUrl && errors.trackingUrl}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="url"
                name="url"
                label="Url"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
