import toast from "react-hot-toast";
import axiosIntance from "../axiosInstance";
import axiosIntanceFiles from "../axiosInstanceFiles";
import { ContainerStatuses } from "../utils";
import { IShipper } from "./shipper-service";
import { IShippingLine } from "./shipping-line-service";
import { IUser } from "./user-service";
import { IVehicle } from "./vehicle-service";
export interface IContainer {
    id: string;
    _id?: string;
    name: string,
    destination?: string,
    shipper?: IShipper,
    status?: keyof typeof ContainerStatuses;
    containerNumber?: string;
    shippingLine?: IShippingLine;
    shippingLineId: string;
    shipperId: string;
    expectedDate?: string;
    notes?: string;
    images?: any;
    imagesToAdd?: any,
    warehouseId?: string;
    warehouse?: IUser;
    documents?: string[];
    vehicles?: IVehicle[];
    vehicleIds?: string[],

  }

  export interface IContaineImageObject {
    imagesToAdd?: any,
    imagesToDelete?: any,
  }

  
interface IContainersResponse {
    count: number,
    data: IContainer[]
}
export const getContainers = (status?: string | null) => {
    return axiosIntance.get<IContainersResponse>(`/containers/`, {
        params: {
          status
        }
    });
};
export const getContainer = (containerId:string) => {
    return axiosIntance.get<IContainer>(`/containers/${containerId}`);
};
export const createContainer = (values: IContainer) => {
    return axiosIntance.post<IContainer>(`/containers/`, values);
};
export const updateContainer = (containerId: string, values: IContainer) => {
    return axiosIntance.put<IContainer>(`/containers/${containerId}`, values);
};
export const deleteContainer = (containerId: string) => {
    return axiosIntance.delete<IContainer>(`/containers/${containerId}`);
};

export const deleteContainerImages = (containerId: string, imagesToDelete: string[]) => {
    return axiosIntance.put(`/files/remove/containers/${containerId}/images`, {files: imagesToDelete});
  };

export const createContainerMethod = async (values: IContainer) => {

    const valuesMod: IContainer = {
      ...values,
      images: undefined,
  };
  const createdContainer = await createContainer(valuesMod);
  const containerData = createdContainer.data;
    if (valuesMod.imagesToAdd && valuesMod.imagesToAdd.length > 0) {
      
        const formData = new FormData();
        for (let i = 0; i < valuesMod.imagesToAdd.length; i++) {
            formData.append("images", valuesMod.imagesToAdd[i]);
        }
        await uploadContainerImagesMethod(containerData.id, formData);
    }
    return createdContainer;
  }


export const uploadContainerImages = (containerId: string, formData: any) => {
    return axiosIntanceFiles.post(`/files/upload/images/container/${containerId}`, formData);
  };

const uploadContainerImagesMethod = async (containerId: string, formData: FormData) => {
    toast.promise(uploadContainerImages(containerId, formData), {
      loading: "Loading images",
      success: "Images uploaded",
      error: "Error when uploading images",
  });
  }

  export const updateContainerMethod = async (containerId: string, values: IContainer & IContaineImageObject) => {
    const valuesMod: IContainer = {
      ...values,
      images: undefined,
  };
    const updatedContainer = await updateContainer(containerId, valuesMod);
        if (containerId && valuesMod.imagesToAdd && valuesMod.imagesToAdd.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < values.imagesToAdd.length; i++) {
                formData.append("images", valuesMod.imagesToAdd[i]);
          }
            await uploadContainerImagesMethod(containerId, formData);
        }
        if(containerId && values.imagesToDelete && values.imagesToDelete.length > 0) {
          await deleteContainerImages(containerId, values.imagesToDelete)
        }

    return updatedContainer;
  }