import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import TourIcon from "@mui/icons-material/Tour";
import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import React from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import noImages from "../../assets/images/no-image.jpg";
import { ImageGalleryComponent } from "../../components/ImageGalleryComponent";
import { StatusIcon, StatusIcons } from "../../components/StatusIcon";
import { FormDivider } from "../../components/styled/FormDivider";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import { IVehicle } from "../../services/vehicle-service";
import { copyText, mapImagesToGalleryItems, trackContainer } from "../../utils";

interface IVehicleDetailsProps {
  isLoading?: boolean
  container?: IContainer;
  selectVehicle: (vehicle: IVehicle) => void;
  handleOpenContainerEdit?: (container: IContainer) => void;
}
export const ContainerDetailsForm: React.FC<IVehicleDetailsProps> = ({
  container,
  selectVehicle,
  handleOpenContainerEdit,
  isLoading = true
}) => {
  const user = getCurrentUser();
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  React.useEffect(() => {
    if (container) {
      const mappedImages = mapImagesToGalleryItems(container.images || []);
      setImages(mappedImages);
    }
  }, [container?.images, container]);

  const shareContainer = () => {
    copyText(
      encodeURI(`https://autosrealm.com/share/container/${container?.id}`),
      "Container URL copied successfully!"
    );
  }
  return (
    <>
      <Grid container spacing={2}>
      <Grid item xs={12}>
            {imagesState && imagesState.length > 0 ? (
              <>
                {isLoading && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
                <div style={{ display: isLoading ? "none" : "block" }}>
                  <ImageGalleryComponent showThumbnails items={imagesState || []} />
                </div>
              </>
            ) : (
              <img style={{ width: "100%" }} src={noImages} alt="no" />
            )}
          </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Container Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton onClick={() => {trackContainer(container?.shippingLine?.trackUrl, container?.name)}}>
              <Tooltip title="Container number">
                <ListItemIcon>
                  <CropFreeIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.name} />
            </ListItemButton>
            <ListItemButton >
              <Tooltip title="Shipping line">
                <ListItemIcon>
                  <DirectionsBoatIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.shippingLine?.name} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Destination">
                <ListItemIcon>
                  <TourIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.destination} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Status">
                <ListItemIcon>
                  {container?.status && <StatusIcon iconName={StatusIcons[container?.status]} />}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.status} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Expected date">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dayjs(container?.expectedDate).format("YYYY-MM-DD")} />
            </ListItemButton>

            {user && (
              <ListItemButton onClick={shareContainer}>
                <Tooltip title="Share vehicle">
                  <ListItemIcon>
                    <ShareIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Client shareable link" />
              </ListItemButton>
            )}
            {handleOpenContainerEdit && container && (
              <ListItemButton onClick={() => {handleOpenContainerEdit(container)}}>
                <Tooltip title="Edit">
                  <ListItemIcon>
                    <EditIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Edit container" />
              </ListItemButton>
              )}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Loaded Vehicles</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            {container?.vehicles &&
              container.vehicles.map((vehicle: IVehicle) => {
                return (
                  <ListItemButton onClick={() => {selectVehicle(vehicle)}}>
                    <Tooltip title={vehicle.description}>
                      <ListItemIcon>
                        <DirectionsCarIcon color="primary" />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={`${vehicle.description} - ${vehicle.vin}`} />
                  </ListItemButton>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
