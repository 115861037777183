import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { IShippingLine } from "../../services/shipping-line-service";

interface ContainerFormProps {
  containerData?: IShippingLine;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const ShippingLineForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  let validationSchema = object({
    name: string().required("Name is required"),
  });
  const userValues = {
    name: ""
  } as IShippingLine;
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={containerData || userValues}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="url"
                name="url"
                label="Url"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="trackUrl"
                name="trackUrl"
                label="Track url"
                value={values.trackUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.trackUrl && Boolean(errors.trackUrl)}
                helperText={touched.trackUrl && errors.trackUrl}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
