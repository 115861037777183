import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  TextField,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IVehicle, searchVehicleInput } from "../../services/vehicle-service";
import { VehicleStatus } from "../../utils";

const VehicleSelectLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
interface VehicleSelectProps {
  handleChange?: (field: string, value: any) => void;
  children?: React.ReactNode;
  title?: string;
  initialValues: IVehicle[] | IVehicle;
  error?: boolean;
  name?: string;
  label?: string;
  vehicleStatus?: keyof typeof VehicleStatus;
  useAsyncOptions?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  fieldName?: string;
}
export const VehicleSelect: React.FC<VehicleSelectProps> = ({
  error,
  name,
  handleChange,
  initialValues,
  label,
  vehicleStatus,
  useAsyncOptions = false,
  disabled = false,
  multiple = true,
  fieldName = "vehicles"
}) => {
  const [open, setOpen] = React.useState(false);
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [searchText, setSearchText] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState<IVehicle[] | any>(
    initialValues
  );
  const loading = open && vehicles.length === 0;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const searchVehicles = async (searchText?: string) => {
    const vehicles = await searchVehicleInput(searchText, vehicleStatus);
    setVehicles([
      ...vehicles.data.data.map((vehicle: IVehicle) => {
        return {
          ...vehicle,
          id: vehicle.id || vehicle._id,
        };
      }),
    ]);
  };
  useEffect(() => {
    if (initialValues && Array.isArray(initialValues)) {
      setSelectedVehicles([
        ...initialValues.map((vehicle: IVehicle) => {
          return {
            ...vehicle,
            id: vehicle.id || vehicle._id,
          };
        }),
      ]);
    } else {
      setSelectedVehicles({
        ...initialValues,
        id: initialValues.id || initialValues._id,
      });
    }
  }, [initialValues]);
  useEffect(() => {
    searchVehicles(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IVehicle,
    { selected }: AutocompleteRenderOptionState
  ) => {
    if(!multiple) {
      return (
        <li {...props}>
          {option.description + "-" + option.vin}
        </li>
      );
    }
    const checked = selectedVehicles.find((vehicle: IVehicle) => {
      return vehicle.id === option.id;
    });
    return (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={Boolean(checked)}
        />
        {option.description + "-" + option.vin}
      </li>
    );
  };
  <Tooltip title={`Only vehicles with status "AT TERMINAL"`}>
    <InfoIcon></InfoIcon>
  </Tooltip>;

  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      size="small"
      id="asynchronous-demo"
      open={open}
      onChange={(event, value) => {
        setSelectedVehicles(value);
        if (handleChange) handleChange(fieldName ?? "vehicles", value);
      }}
      renderOption={renderOption}
      value={selectedVehicles}
      disableCloseOnSelect={multiple}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.description + " - " + option.vin}
      options={vehicles}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select vehicles"
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
