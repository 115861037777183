import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import NotesIcon from '@mui/icons-material/Notes';
import { Grid, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { useParams } from "react-router-dom";
import noImages from "../../assets/images/no-image.jpg";
import { CommonDialog } from "../../components/CommonDialog";
import { ImageGalleryComponent } from "../../components/ImageGalleryComponent";
import { FormDivider } from "../../components/styled/FormDivider";
import { ClaimStatus, IClaim } from "../../services/vehicle-service";
import { copyText, mapImagesToGalleryItems } from "../../utils";
interface ClaimPreviewProps {
  claimData?: IClaim;
  handleToggleOpen?: () => void;
  open?: boolean;
  share?: boolean;
}
export const ClaimPreview: React.FC<ClaimPreviewProps> = ({
  open = false,
  claimData,
  share,
  handleToggleOpen,
}) => {
  const params = useParams();
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  useEffect(() => {
    if (claimData) {
      const mappedImages = mapImagesToGalleryItems(claimData.images || []);
      setImages(mappedImages);
    }
  }, [claimData?.images, claimData]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
  };
  const handleClose = () => {
    return false;
  };


  return (
    <CommonDialog
      fullWidth
      maxWidth="md"
      title={`Claim Details ${claimData?.description} ${
        claimData?.status
          ? ` - ${
              ClaimStatus[claimData.status as keyof typeof ClaimStatus]
            }`
          : ""
      }`}
      handleToggleOpen={toggleOpenDialog}
      open
      onClose={handleClose}
    >
    <>
      <Grid container spacing={2}>
      <Grid item xs={12}>
            {imagesState && imagesState.length > 0 ? (
                <ImageGalleryComponent showThumbnails items={imagesState || []} />
            ) : (
              <img style={{ width: "100%" }} src={noImages} alt="no" />
            )}
          </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Vehicle Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  claimData?.vehicleId?.vin &&
                  copyText(claimData?.vehicleId?.vin, "Vin copied successfully")
                }
              >
                <Tooltip title="Copy vin">
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={claimData?.vehicleId.vin} />
              </ListItemButton>
            <ListItemButton>
              <Tooltip title="Created date">
                <ListItemIcon>
                  <DirectionsCarIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={claimData?.vehicleId.description} />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Claim Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton>
              <Tooltip title="Created date">
                <ListItemIcon>
                  <NotesIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={claimData?.description} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Created date">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dayjs(claimData?.createdAt).format("YYYY-MM-DD")} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </>
    </CommonDialog>
  );
};
