import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { CommonDialog } from "../../components/CommonDialog";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { getCurrentUser } from "../../services/auth-service";
import {
  createVehicleMethod,
  ICreateVehicle,
  sendSignalMessage,
  updateVehicleMethod,
} from "../../services/vehicle-service";
import { useVehicle } from "./hooks/useVehicle";
import { VehicleForm } from "./VehicleForm";
interface IVehicleDetailsProps {
  vehicleId?: string;
  handleToggleOpen: () => void;
  submitCallback?: () => void;
}
export const VehicleDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicleId,
}) => {
  const user = getCurrentUser();
  const formRef = useRef<FormikProps<any>>(null);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [isLoading, vehicleData] = useVehicle(vehicleId);

  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };

  const handleSubmit = async (values: ICreateVehicle) => {
    const { statusHistory } = values;
    const lastStatus = statusHistory.slice(-1)[0];
    if (vehicleId) {
      await updateVehicleMethod(vehicleId, {
        ...values,
        createdBy: user?.id,
        status: lastStatus?.status,
      });
      if (values.user?.phone) {
        sendSignalMessage({
          message: `Vehicle ${values.description} - ${values.vin} was updated.
              Check out more at:  https://autosrealm.com/search-vehicle/${values?.vin}`,
          recipients: [values.user.phone],
          sender: "+359896730058",
        });
      }
      toast.success(`Vehicle updated successfully!`);

      if (submitCallback) submitCallback();
    } else {
      const createdVehicle = await createVehicleMethod({
        ...values,
        createdBy: user?.id,
        status: lastStatus.status,
      }).catch((error: any) => {
        const { response } = error;
        if (response?.status === 409) {
          toast.error(`Vehicle ${values.vin} already exists!`);
        }
        return response;
      });
      const { data } = createdVehicle;
      if (values.user?.phone) {
        sendSignalMessage({
          message: `Vehicle ${values.description} - ${values.vin} was created.
              Check out more at:  https://autosrealm.com/search-vehicle/${values?.vin}`,
          recipients: [values.user.phone],
          sender: "+359896730058",
        });
      }
      if (data.id) {
        toast.success(`Vehicle updated successfully!`);

        if (submitCallback) submitCallback();
      }
    }
  };
  const dialogClose = () => {
    console.log(formRef.current?.dirty, "DIRT");
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        fullScreen
        title={vehicleId ? "Edit vehicle" : "Add vehicle"}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
        {!isLoading && (
          <VehicleForm
            vehicleData={vehicleData}
            formRef={formRef}
            handleSubmit={handleSubmit}
          />
        )}
      </CommonDialog>
      <ConfirmDialog
        title="Changes are unsaved!"
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        Some of your changes are not saved, do you really want to close the
        form?
      </ConfirmDialog>
    </>
  );
};
