import axiosIntance from "../axiosInstance";
export interface IShippingLine {
    id: string,
    name: string,
    trackUrl: string,
    url?: string,
  }
interface IShippingLineResponse {
    data: IShippingLine[],
    count: number
}
export const getShippingLines = () => {
    return axiosIntance.get<IShippingLineResponse>(`/shipping-lines/`);
};
export const getShippingLine = (auctionId: string) => {
    return axiosIntance.get<IShippingLine>(`/shipping-lines/${auctionId}`);
};
export const createShippingLine = (values: any) => {
    return axiosIntance.post<IShippingLine>("/shipping-lines", values);
}
export const updateShippingLine = (auctionId: string, values: any) => {
    return axiosIntance.put<IShippingLine>(`/shipping-lines/${auctionId}`, values);
}
export const deleteShippingLine = (truckId: string) => {
    return axiosIntance.delete<IShippingLine>(`/shipping-lines/${truckId}`);
};