import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { getAuctions, IAuction } from "../../services/auction-service";
import { TextFieldWrapper } from "./TextFieldWrapper";
interface UserSelectProps {
  handleChange?: (selectedAuction: IAuction | null) => void;
  children?: React.ReactNode;
  title?: string;
  value?: IAuction | null;
  error?: boolean;
  name?: string;
  label?: string;
}
export const AuctionSelect = React.memo(function Images(props: UserSelectProps & TextFieldProps) {
  return <AuctionSelectInput {...props} />
});
export const AuctionSelectInput: React.FC<UserSelectProps & TextFieldProps> = ({
  error,
  helperText,
  name,
  handleChange,
  value,
  label,
}) => {
  const [auctions, setAuctions] = useState<IAuction[]>([]);
  const [auctionId, setSelectedAuction] = useState<IAuction | null>(null);
  const [open, setOpen] = useState(false);
  const loadAuctions = useCallback(async () => {
    const {data: auctionsData} = await getAuctions();
    setAuctions(auctionsData.data)
  }, [])
  useEffect(() => {
    loadAuctions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(value)
    setSelectedAuction(value);
  }, [value])
  const onSelectAuction = (event: React.SyntheticEvent, value: IAuction | null) => {
    if(value)
    setSelectedAuction(value)
    setOpen(false)
    if(handleChange)
    handleChange(value);
  }
  const handleOpen = () => {
    setOpen(true);

  }
  return (
    <Autocomplete
      open={open}
      size="small"
      value={auctionId}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={handleOpen}
      id="checkboxes-tags-demo"
      options={auctions}
      disableCloseOnSelect
      onChange={onSelectAuction}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextFieldWrapper error={error} helperText={helperText} {...params} label="Select Auction" placeholder="Search auctions" />
      )}
    />
  );
};
