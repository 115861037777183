import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageList from "@mui/material/ImageList";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FieldArray, Form, Formik } from "formik";
import update from "immutability-helper";
import React, { useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactImageGalleryItem } from "react-image-gallery";
import { object, string } from "yup";
import { DocumentRendererComponent } from "../../components/DocumentRenderer";
import { FormDivider } from "../../components/styled/FormDivider";

import { AuctionSelect } from "../../components/FormElements/AuctionSelect";
import { TextFieldWrapper } from "../../components/FormElements/TextFieldWrapper";
import { UserSelect } from "../../components/FormElements/UserSelect";
import { IAuction } from "../../services/auction-service";
import {
  decodeVinNhtsa,
  ICreateVehicle,
  IVehicle,
} from "../../services/vehicle-service";
import {
  decodeVin,
  UserRoles,
  VehicleStatus,
  VehicleTypes,
  viewStock,
} from "../../utils";
import { DraggableImage } from "./DraggableImage";

interface VehicleFormProps {
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
  vehicleData?: IVehicle;
}
const MAX_FILE_SIZE = 5096; // 3MB
export const VehicleForm: React.FC<VehicleFormProps> = ({
  formRef,
  vehicleData,
  handleSubmit,
}) => {
  const KEYS_OPTION = [false, true, null];
  const [keys, setKeys] = React.useState(
    vehicleData && Object.keys(vehicleData).length > 0
      ? KEYS_OPTION.indexOf(vehicleData?.keys)
      : 0
  );
  const [imagesState, setImages] = React.useState<any[]>([]);
  const [documentsState, setDocuments] = React.useState<any[]>([]);
  const [imagesToDeleteState, setImagesToDelete] = React.useState<any>([]);
  const [imagesToAdd, setImagesToAdd] = React.useState<any>([]);
  const [documentsToDeleteState, setDocumentsToDeleteState] =
    React.useState<any>([]);
  const [documentsToAdd, setDocumentsToAdd] = React.useState<any>([]);
  const imageObject = (file: any) => {
    return {
      name: file.name ? file.name : file,
      original: typeof file === "object" ? URL.createObjectURL(file) : file,
      thumbnail: typeof file === "object" ? URL.createObjectURL(file) : file,
    };
  };
  const handleMultipleImages = useCallback(
    (targetFiles: any) => {
      const selectedFiles: ReactImageGalleryItem[] = [];
      const images: any = [];
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        images.push(file);
        return selectedFiles.push(imageObject(file));
      });
      setImages(imagesState.concat(selectedFiles));
      setImagesToAdd(imagesToAdd.concat(images));
    },
    [imagesState, imagesToAdd]
  );
  const handleMultipleDocuments = useCallback(
    (targetFiles: any) => {
      const selectedFiles: ReactImageGalleryItem[] = [];
      const documents: any = [];
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        documents.push(file);
        return selectedFiles.push(imageObject(file));
      });
      setDocuments(documentsState.concat(selectedFiles));
      setDocumentsToAdd(documentsToAdd.concat(documents));
    },
    [documentsState, documentsToAdd]
  );
  const moveImage = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      setFieldValue: (field: string, value: any) => void
    ) => {
      setImages((prevImages) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
      setImagesToAdd((prevImages: any) =>
        update(prevImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevImages[dragIndex]],
          ],
        })
      );
    },
    []
  );
  useEffect(() => {
    if (vehicleData?.images)
      setImages([
        ...vehicleData.images.map((image: any) => {
          return imageObject(image);
        }),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleData]);
  let validationSchema = object({
    vin: string()
      .required("Vin is required")
      .min(17, "Vin must be exactly 17 symbols")
      .max(17, "Vin must be exactly 17 symbols"),
    description: string().required("Description is required"),
    stockNumber: string().required("Stock is required"),
    userId: string().required("User is required"),
    warehouseId: string().required("Warehouse is required"),
  });
  const handleRemoveImage = (
    imageToDelete: any,
    images: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setImages((current) => current.filter((image) => imageToDelete !== image));
    setFieldValue(
      "images",
      images?.filter((image: any) => {
        return image !== imageToDelete;
      })
    );
    if (vehicleData) {
      setImagesToDelete(
        imagesToDeleteState.concat(imageToDelete.name.split("%2F").pop())
      );
    }
  };
  const handleRemoveDocument = (
    documentToDelete: any,
    documents: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setDocuments((current) =>
      current.filter((image) => documentToDelete !== image.name)
    );
    setFieldValue(
      "documents",
      documents?.filter((document: any) => {
        return document.name !== documentToDelete;
      })
    );
    if (vehicleData) {
      setDocumentsToDeleteState(
        documentsToDeleteState.concat(documentToDelete.split("%2F").pop())
      );
    }
  };
  const handleViewStock = (stockNumber: string, auction?: IAuction) => {
    viewStock(stockNumber, auction);
  };
  // @ts-ignore
  const vehicleValues = {
    expectedDate: "",
    titleSentDate: "",
    type: "CAR",
    keys: KEYS_OPTION[0],
    statusHistory: [
      {
        date: dayjs().format("YYYY-MM-DD"),
        status: "TRANSIT",
      },
    ],
  } as ICreateVehicle;
  const handleVinChange = async (
    props: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const { value } = props.target;
    setFieldValue("vin", value);
    if (value && value.length === 17) {
      const { data } = await decodeVinNhtsa(value);
      if (data.Results[0] && data.Results[0].Make) {
        setFieldValue(
          "description",
          `${data.Results[0].ModelYear} ${data.Results[0].Make} ${data.Results[0].Model}`
        );
      }
    }
  };
  return (
    <Formik
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={
        vehicleData && Object.keys(vehicleData).length > 0
          ? {
              ...vehicleData,

              userId: vehicleData.user?._id,
              warehouseId: vehicleData.warehouse?._id,
              documents: vehicleData.documents?.map((document: any) => {
                return {
                  url: document,
                  name: document.split("%2F").pop(),
                };
              }),
            }
          : vehicleValues
      }
      onSubmit={async (values: IVehicle) => {
        handleSubmit({
          ...values,
          imagesToAdd: imagesToAdd,
          imagesToDelete: imagesToDeleteState,
          documentsToAdd: documentsToAdd,
          documentsToDelete: documentsToDeleteState,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <FormDivider variant="fullWidth">Main Information</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <UserSelect
                required
                role={[UserRoles.ADMIN, UserRoles.CLIENT]}
                handleChange={(user) => setFieldValue("userId", user?.id)}
                values={vehicleData?.user}
                error={Boolean(errors.userId)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UserSelect
                required
                role={[UserRoles.WAREHOUSE]}
                label="Warehouse"
                handleChange={(user) => setFieldValue("warehouseId", user?.id)}
                values={vehicleData?.warehouse}
                error={Boolean(errors.warehouseId)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {values.stockNumber && (
                        <IconButton
                          onClick={() => {
                            if (!values.auction) {
                              setFieldError(
                                "auction",
                                "Auction with tracking url is required for this action"
                              );
                              return;
                            }
                            setFieldError("auction", undefined);
                            handleViewStock(values.stockNumber, values.auction);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                id="stockNumber"
                name="stockNumber"
                label="Stock Number"
                value={values.stockNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.stockNumber}
                error={Boolean(errors.stockNumber)}
              ></TextFieldWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                inputProps={{
                  maxLength: 50,
                }}
                multiline
                size="small"
                fullWidth
                id="description"
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.description)}
                helperText={errors.description}
              ></TextFieldWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                inputProps={{
                  maxLength: 17,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {values.vin && (
                        <IconButton onClick={() => decodeVin(values.vin)}>
                          <VisibilityIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="vin"
                name="vin"
                label="Vin"
                value={values.vin?.toUpperCase()}
                onChange={(props: MuiTextFieldProps) => {
                  handleVinChange(props, setFieldValue);
                }}
                onBlur={handleBlur}
                error={Boolean(errors.vin)}
                helperText={errors.vin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                fullWidth
                id="type"
                name="type"
                label="Type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.type)}
                helperText={errors.type}
              >
                {Object.keys(VehicleTypes).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {VehicleTypes[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={keys === 2}
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      const newStatus = (keys + 1) % KEYS_OPTION.length;
                      setKeys(newStatus);
                      setFieldValue("keys", KEYS_OPTION[newStatus]);
                    }}
                    checked={values.keys}
                  />
                }
                label={`Keys Present ${values.keys === null ? "N/A" : ""}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("paid", event.target.checked);
                    }}
                    checked={values.paid}
                  />
                }
                label="Paid"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldWrapper
                multiline
                size="small"
                inputProps={{
                  maxLength: 300,
                }}
                fullWidth
                id="notes"
                name="notes"
                label="Notes"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.notes)}
                helperText={errors.notes}
              ></TextFieldWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <AuctionSelect
                handleChange={(auction) => {
                  setFieldValue("auction", auction);
                  setFieldValue("auctionId", auction?.id);
                }}
                error={Boolean(errors.auction)}
                helperText={errors.auction}
                value={vehicleData?.auction}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                size="small"
                fullWidth
                id="auctionLocation"
                name="auctionLocation"
                label="Auction Location"
                value={values.auctionLocation}
                onChange={handleChange}
                error={
                  touched.auctionLocation && Boolean(errors.auctionLocation)
                }
              ></TextFieldWrapper>
            </Grid>
          </Grid>

          {/* <FormDivider variant="fullWidth">Auction Information</FormDivider>
          <Grid container spacing={4}>
                {console.log(imagesState, "IMAGES STATE")}
          </Grid> */}

          <FormDivider variant="fullWidth">Shipping Information</FormDivider>
          <Grid container spacing={4}>
            {/* <Grid item xs={12} md={6}>
              <ContainerSelect
                handleChange={(container) => {
                  setFieldValue("containerName", container?.name || null);
                  setFieldValue("containerId", container?.id || null);
                }}
                value={vehicleData?.container}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                size="small"
                fullWidth
                id="destination"
                name="destination"
                label="Destination"
                value={values.destination}
                onChange={handleChange}
              ></TextFieldWrapper>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    label="Expected date"
                    inputFormat="DD/MM/YYYY"
                    value={values.expectedDate || null}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => (
                      <TextFieldWrapper fullWidth size="small" {...params} />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label="Expected date"
                    inputFormat="MM/DD/YYYY"
                    value={values.expectedDate || null}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                id="finalDestination"
                name="finalDestination"
                label="Final destination"
                value={values.finalDestination}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.finalDestination)}
                helperText={errors.finalDestination}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    label="Title sent date"
                    inputFormat="DD/MM/YYYY"
                    value={values.titleSentDate || null}
                    onChange={(value) => {
                      setFieldValue("titleSentDate", value);
                    }}
                    renderInput={(params) => (
                      <TextFieldWrapper fullWidth size="small" {...params} />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label="Title sent date"
                    inputFormat="MM/DD/YYYY"
                    value={values.titleSentDate || null}
                    onChange={(value) => {
                      setFieldValue("titleSentDate", value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <Button variant="contained" component="label">
                Upload Documents
                <input
                  hidden
                  id="documents"
                  name="documents"
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => {
                    const files = event.target.files;
                    handleMultipleDocuments(files);
                    if (files) {
                      const myFiles = Array.from(files);
                      const groupFiles = values.documents?.concat(myFiles);
                      setFieldValue(
                        "documents",
                        values.documents ? groupFiles : myFiles
                      );
                    }
                  }}
                  multiple
                />
              </Button>
            </Grid>
            <Grid container item xs={12} md={6}>
              <Button variant="contained" component="label">
                Upload images
                <input
                  hidden
                  id="images"
                  name="images"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(event) => {
                    const files = event.target.files;
                    handleMultipleImages(files);
                    if (files) {
                      let myFiles = Array.from(files);
                      setFieldValue("images", myFiles);
                      if (
                        values.statusHistory &&
                        values.statusHistory.length === 1 &&
                        values.statusHistory[0].status === "TRANSIT"
                      ) {
                        // set status to AT_TERMINAL if images are selected
                        setFieldValue("statusHistory", [
                          ...values.statusHistory,
                          {
                            status: "AT_TERMINAL",
                            date: dayjs(Date.now()).format("YYYY-MM-DD"),
                          },
                        ]);
                      }
                    }
                  }}
                  multiple
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <DocumentRendererComponent
                deleteDocument={
                  (documentName) =>
                    handleRemoveDocument(
                      documentName,
                      values.documents,
                      setFieldValue
                    )
                  //deleteDocument(values.documents, documentName, setFieldValue)
                }
                documents={values.documents}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <GoogleDriveGallery /> */}
              <ImageList variant="quilted" cols={4} rowHeight={120}>
                <DndProvider backend={HTML5Backend}>
                  {imagesState?.map((item, index) => (
                    <DraggableImage
                      id={index}
                      moveCard={(dragIndex: number, hoverIndex: number) =>
                        moveImage(dragIndex, hoverIndex, setFieldValue)
                      }
                      removeImage={(imageToDelete) =>
                        handleRemoveImage(
                          imageToDelete,
                          values?.images,
                          setFieldValue
                        )
                      }
                      image={item}
                      index={index}
                    />
                  ))}
                </DndProvider>
              </ImageList>
            </Grid>
          </Grid>
          <FormDivider variant="fullWidth">Status History</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <FieldArray
                name="statusHistory"
                render={(arrayHelpers) => (
                  <div>
                    {values.statusHistory?.map((status: any, index: number) => (
                      <Grid
                        style={{ paddingBottom: 10 }}
                        gap={1}
                        container
                        key={index}
                      >
                        {/** both these conventions do the same */}
                        <Grid item xs={12} md={3}>
                          <TextField
                            label="Status"
                            fullWidth
                            select
                            size="small"
                            value={values.statusHistory[index].status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`statusHistory[${index}].status`}
                          >
                            {(
                              Object.keys(VehicleStatus) as Array<
                                keyof typeof VehicleStatus
                              >
                            ).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {VehicleStatus[key]}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              renderInput={(params) => (
                                <TextFieldWrapper
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                              label="Date"
                              inputFormat="DD/MM/YYYY"
                              value={values.statusHistory[index].date}
                              onChange={(value) => {
                                setFieldValue(
                                  `statusHistory[${index}].date`,
                                  value
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextFieldWrapper
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id={`statusHistory[${index}].note`}
                            name={`statusHistory[${index}].note`}
                            label="Note"
                            value={values.statusHistory[index].note}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {index > 0 && (
                            <IconButton
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveIcon fontSize="medium" />
                            </IconButton>
                          )}
                          {index + 1 === values?.statusHistory.length && (
                            <IconButton
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  status: "",
                                  date: dayjs(Date.now()).format("YYYY-MM-DD"),
                                })
                              }
                            >
                              <AddIcon fontSize="medium" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                )}
              />
              {/* <TextField
                select
                size="small"
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.status)}
                helperText={errors.status}
              >
                {Object.keys(VehicleStatus).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {VehicleStatus[key]}
                    </MenuItem>
                  );
                })}
              </TextField> */}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
