import { FormikProps } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import {
  createContainerMethod,
  getContainer,
  IContainer,
  updateContainerMethod
} from "../../services/container-service";
import { IVehicle } from "../../services/vehicle-service";
import { ContainerForm } from "./ContainerForm";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
  containerId?: string;
  redirectBack?: boolean;
}
export const ContainerDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
  redirectBack = true,
  containerId
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const [containerData, setContainerData] = useState<IContainer>();
  const [errors, setErrors] = useState<any>();
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (containerId) {
      const { data } = await getContainer(containerId);
      setContainerData(data);
      setOpenUserForm(true);
    }
  }, [containerId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: IContainer) => {
    const valuesMod = {
      ...values,
      vehicleIds:
        values.vehicles &&
        values.vehicles.map((vehicle: IVehicle) => {
          return vehicle.id || vehicle._id;
        }),
    };
    if (!containerId)
      toast.promise(createContainerMethod(valuesMod), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/containers");
          return `Successfully created container ${res.data.containerNumber}`;
        },
        error: ({ response }) => {
          setErrors(response.data?.message);
          return "Error when creating container";
        },
      });
    else {
      toast.promise(updateContainerMethod(containerId, valuesMod), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          if(redirectBack)
          navigate("/containers");
          return `Successfully updated container ${res.data.name}`;
        },
        error: (res) => {
          return "Error when updating container";
        },
      });
    }
  };
  useEffect(() => {
    if (containerId) {
      loadUserData();
    } else {
      setOpenUserForm(true);
    }
  }, [containerId, loadUserData]);
  const dialogClose = () => {
    console.log(formRef.current?.dirty, "DIRT");
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        title={containerId ? "Edit container" : "Add container"}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
        {openUserForm && (
          <ContainerForm
            containerData={containerData}
            handleToggleOpen={handleToggleOpen}
            formRef={formRef}
            handleSubmit={handleSubmit}
          />
        )}
      </CommonDialog>
      <ConfirmDialog
        title="Changes are unsaved!"
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        Some of your changes are not saved, do you really want to close the
        form?
      </ConfirmDialog>
    </>
  );
};
